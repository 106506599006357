import { IProviderMediaFile } from 'src/types/provider';
import { IMediaFile } from 'src/types/tours';
import { instanceApi } from 'src/utils/axios';
import { CRUDService } from './core/crudService';
import { MediaFileService } from './mediaFileService';

const mediaFileService = MediaFileService.getInstance();
export class ProviderMediaFileService extends CRUDService<IProviderMediaFile> {
  protected apiPath = 'api/app/provider-media-file';


  async syncFiles(newFiles: IMediaFile[], input: any = {}) {
    const request = {
      ...input,
      files: newFiles.map((file, index) => ({ mediaFileId: file.id, fileOrder: index }))
    }
    return instanceApi.post<IProviderMediaFile[]>(`${this.apiPath}/multiple-files`, request)
  }

  mapItem(item) {
    if (item.mediaFileDto) {
      item.mediaFileDto = mediaFileService.mapItem(item.mediaFileDto);
    }
    item.thumbnail = item.isVideo ? item.thumbnail : item.path;
    return item;
  }
}