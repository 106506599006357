import React, { FC, useCallback } from 'react';
import { TextFieldProps } from 'formik-material-ui';
import { TextField } from '@material-ui/core';
import { Autocomplete, createFilterOptions } from '@material-ui/lab';
import { FieldProps } from 'formik';
import { find } from 'lodash';

export interface AutocompleteInputProps extends FieldProps {
  label?: string,
  options?: any[],
  variant?: "filled" | "standard" | "outlined",
  inputParams?: TextFieldProps,
  labelKey?: string,
  idKey?: string,
  addNewLabel?: string;
  fullWidth?: boolean;
  createItem?: (event: any) => any;
}

const filter = createFilterOptions();

const AutocompleteInput: FC<AutocompleteInputProps> = (props) => {
  const {
    form: { setFieldValue },
    field: {
      name,
      value,
    },
    label,
    options,
    variant,
    labelKey = 'title',
    idKey = 'id',
    createItem,
    addNewLabel,
    fullWidth,
  } = props;

  const handleChange = useCallback(
    async (_event: any, newValue: any) => {
      if (createItem && newValue && newValue.inputValue) {
        const item = await createItem(newValue);
        setFieldValue(name, item);
      } else {
        setFieldValue(name, newValue ? newValue[idKey] : null);
      }
    },
    [setFieldValue, createItem, name, idKey]);

  const getOptionLabel = useCallback(
    (option) => {
      return option[labelKey] || '';
    },
    [labelKey],
  )

  const handleGetOptionSelected = useCallback(
    (options: any) => {
      return options[idKey] === value;
    },
    [idKey, value]
  );

  const handleFilterOptions = useCallback(
    (options: any[], params) => {
      let filtered = filter(options, params);
      const foundItem = find(
        options,
        option => option[labelKey] === params.inputValue
      );
      if (createItem && !foundItem) {
        if (params.inputValue !== '') {
          filtered = ([
            {
              inputValue: params.inputValue,
              [labelKey]: `${addNewLabel} "${params.inputValue}"`
            }
          ] as any).concat(filtered);
        }
      }
      return filtered;
    },
    [createItem, addNewLabel, labelKey]
  );


  const selectedOption = find(options, { [idKey]: value })

  return (
    <Autocomplete
      fullWidth={fullWidth}
      options={options}
      value={selectedOption}
      filterOptions={handleFilterOptions}
      getOptionLabel={getOptionLabel}
      getOptionSelected={handleGetOptionSelected}
      onChange={handleChange}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          variant={variant}
          inputProps={{
            ...params.inputProps,
            form: {
              autocomplete: 'off',
            }
          }}
        />
      )}
    />
  )
}

export default AutocompleteInput