/* eslint-disable no-unused-vars */
import FullCalendar from '@fullcalendar/react'
import { IChild } from './child'
import { IBaseEntity } from './core'
import { IParent } from './parent'
import { IProvider } from './provider'

export interface Event {
  id: string
  allDay: boolean
  color?: string
  description: string
  end: Date
  start: Date
  title: string
}

export interface ICalendarEvent extends IBaseEntity {
  providerId?: string
  provider?: IProvider
  title?: string
  description?: string
  allDay?: boolean
  frequency?: CalendarEventFrequencyType
  startDate?: string
  endDate?: string
  startTime?: string
  endTime?: string
  meetingLocation?: MeetingLocationByProviderType
  zoomMeetingId?: string
  nullable?: boolean
  parents?: ICalendarEventParent[]
  childs?: ICalendarEventChild[]
  meetingType?: string
  duration?: number
  latestTimePriorBeforeBooking?: number
  bufferBeforeMeeting?: number
  bufferAfterMeeting?: number
  daysInTheFuture?: number
  overlapDates?: [
    {
      startTime: string
      endTime: string
      meetingLocation: MeetingLocationByProviderType
      date: string
    }
  ]
  mondayHours?: [
    {
      startTime: string
      endTime: string
    }
  ]
  tuesdayHours?: [
    {
      startTime: string
      endTime: string
    }
  ]
  wednesdayHours?: [
    {
      startTime: string
      endTime: string
    }
  ]
  thursdayHours?: [
    {
      startTime: string
      endTime: string
    }
  ]
  fridayHours?: [
    {
      startTime: string
      endTime: string
    }
  ]
  saturdayHours?: [
    {
      startTime: string
      endTime: string
    }
  ]
  sundayHours?: [
    {
      startTime: string
      endTime: string
    }
  ]
}

export interface IFullCalendarEvent extends FullCalendar {
  id?: string
  groupId?: string
  title?: string
  description?: string
  allDay?: boolean
  start?: Date
  end?: Date
  data?: ICalendarEvent
}

export interface ICalendarRange {
  start: Date
  end: Date
}

export enum CalendarEventFrequencyType {
  NOT_REPEAT = 0,
  DAILY = 1,
  WEEKLY = 3,
  MONTHLY = 4
}

export enum MeetingLocationByProviderType {
  CENTER = 0,
  ZOOM = 1,
  PARENT_CHOICE = 2
}

export type View = 'dayGridMonth' | 'timeGridWeek' | 'timeGridDay' | 'listWeek'

export interface ICalendarEventChild {
  calendarEventId?: string
  childId?: string
  child?: IChild
}
export interface ICalendarEventParent {
  calendarEventId?: string
  parentId?: string
  parent?: IParent
}

export interface IGetEventBetweenDatesInput {
  StartDate: string
  EndtDate: string
}

export interface ICreateCalendarEventChildsInput {
  calendarEventId?: string
  childsId?: string[]
}

export interface IUpdateCalendarEventChildsInput {
  calendarEventId?: string
  childsId?: string[]
}

export interface ICreateCalendarEventParentsInput {
  calendarEventId?: string
  parents?: {
    parentId?: string
    meetingLocation?: EnumMeetingLocationByParentType
  }[]
}

export interface IUpdateCalendarEventParentsInput {
  calendarEventId?: string
  parents?: {
    parentId?: string
    meetingLocation?: EnumMeetingLocationByParentType
  }[]
}

export enum EnumMeetingLocationByParentType {
  Center = 0,
  Zoom = 1
}
