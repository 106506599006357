import moment from 'moment'
import { IPagination } from 'src/types'
import { IChild } from 'src/types/child'
import { instanceApi } from 'src/utils/axios'
import { CRUDService } from './core/crudService'

export class ChildService extends CRUDService<IChild> {
  protected apiPath = '/api/app/child'

  protected fillable = [
    'firstName',
    'lastName',
    'imageUrl',
    'startDate',
    'endDate',
    'familyId',
    'familyName',
    'parentId',
    'parentName',
    'addressId',
    'dateOfBirth',
    'status',
    'notes'
  ]

  createDealChilds(parentId: string) {
    return instanceApi.get<IChild>(
      `${this.apiPath}/${parentId}/children-by-parent-id`
    )
  }

  updateDealChilds() {
    return instanceApi.get<IPagination<IChild>>(
      `${this.apiPath}/children-of-current-logged-in-parent`
    )
  }

  createParentChild(request: IChild) {
    return instanceApi.post<IChild>(`${this.apiPath}/by-parent`, request)
  }

  updateParentChild(request: IChild) {
    return instanceApi.put<IChild>(`${this.apiPath}/by-parent`, request)
  }

  mapItem(row: IChild) {
    console.log(row.dateOfBirth)
    if (row.dateOfBirth) {
      row.age = parseFloat(
        moment().diff(row.dateOfBirth, 'year', true).toFixed(1)
      )
    }

    return row
  }
}
