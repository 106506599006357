import { SubscriptionEditionsEnum, SubscriptionStatusEnum } from "src/types/subscription";

export function hasSubscription(subscription, name: SubscriptionEditionsEnum) {
  if (name === SubscriptionEditionsEnum.STANDARD) {
    return !subscription || subscription?.subscription?.status === SubscriptionStatusEnum.VOID || subscription?.subscription?.edition?.displayName === name;
  }
  return subscription?.subscription?.edition?.displayName === name && subscription.subscription.status !== SubscriptionStatusEnum.VOID;
}

export function getSubscriptionName(subscription): SubscriptionEditionsEnum {
  if (!subscription || subscription?.subscription?.status === SubscriptionStatusEnum.VOID) {
    return SubscriptionEditionsEnum.STANDARD;
  }
  return subscription?.subscription?.edition?.displayName;
}


export function hasAnySubscription(subscription, names: SubscriptionEditionsEnum[]) {
  for (let index = 0; index < names.length; index++) {
    const name = names[index];
    if (name === SubscriptionEditionsEnum.STANDARD) {
      return !subscription || subscription?.subscription?.status === SubscriptionStatusEnum.VOID || subscription?.subscription?.edition?.displayName === name;
    }
    if (subscription?.subscription?.edition?.displayName === name && subscription.subscription.status !== SubscriptionStatusEnum.VOID) {
      return true;
    }
  }
  return false;
}
