/* eslint-disable no-unused-vars */
import { IEnrollmentProgramSchedule } from 'src'
import { IBaseEntity } from './core'

export interface IEnrollmentPrice extends IBaseEntity {
  name?: string
  amount?: number
  providerId?: string
  enrollmentProgramScheduleId?: string
  enrollmentProgramSchedule?: IEnrollmentProgramSchedule
  terms?: EnrollmentPriceTermEnum
  notes?: string
}

export enum EnrollmentPriceTermEnum {
  DAILY = 0,
  WEEKLY = 1,
  MONTHLY = 2,
  TOTAL = 3
}
