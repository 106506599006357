/* eslint-disable no-unused-vars */
import { IPhotoCollection } from 'src/types/image'
import { instanceApi } from 'src/utils/axios'
import { CRUDService } from './core/crudService'

export class ImageCollectionService extends CRUDService<IPhotoCollection> {
  protected apiPath = '/api/app/provider/image-collection'
  protected apiCollectionPath = '/api/app/provider/image-feature-library'

  getImageCollection() {
    return instanceApi.get<IPhotoCollection[]>(`${this.apiPath}`)
  }

  getFreeStockCollection() {
    return instanceApi.get<any[]>(`${this.apiCollectionPath}?Name=Stock`)
  }
}
