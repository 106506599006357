/* eslint-disable no-unused-vars */
import { CRUDService } from './core/crudService'
import { instanceApi } from 'src/utils'
import { IEnrollmentTimeline } from 'src/types/program'

export class MicrositeTimelineSlideService extends CRUDService<IEnrollmentTimeline> {
  protected apiPath = '/api/app/microsite-timeline-slideshow'
  protected fillable = []

  createMultipleSlideshows(request: Partial<IEnrollmentTimeline> = {}) {
    return instanceApi.post<IEnrollmentTimeline>(
      `${this.apiPath}/multiple-slideshows`,
      request
    )
  }
}
