import { INewProvider, IUserAccount } from 'src/types'
import { instanceApi as authAxios } from 'src/utils/authAxios'
import { instanceApi } from 'src/utils/axios'
import { Service } from './core/service'

export class ProviderAccountService extends Service {
  protected apiPath = ''
  protected api = '/api/app/provider/by-user'

  register(request: IUserAccount) {
    return authAxios.get<any>(
      `${this.apiPath}/Account/Register?handler=Remote&email=${request.email}&password=${request.password}&firstName=${request.firstName}&lastName=${request.lastName}`
    )
  }

  getTenantId(request: IUserAccount) {
    return authAxios.get<any>(
      `${this.apiPath}/Account/Login?handler=Tenant&email=${request.email}&password=${request.password}`
    )
  }

  getToken(request: IUserAccount) {
    return authAxios.post<any>(`${this.apiPath}/connect/token`, request)
  }

  createProvider(request: INewProvider) {
    return instanceApi.post<any>(`${this.api}`, request)
  }
}
