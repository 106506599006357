
import { ISlide } from 'src/types/slide';
import { instanceApi } from 'src/utils/axios';
import { CRUDService } from './core/crudService';

export class SlideService extends CRUDService<ISlide> {

  protected apiPath = 'api/app/slide';

  protected fillable = [
    "title",
    "subTitle",
    "description",
    "imageURL",
    "isPublished",
  ]

  updateStatus(id: string, status: 'publish' | 'unpublish') {
    return instanceApi.post(`${this.apiPath}/${id}/${status}`, {})
  }

  getWithDetails(id: any) {
    return instanceApi.get(`${this.apiPath}/${id}/with-details`, {})
      .then((resp) => {
        resp.data = this.mapItem(resp.data);
        return resp;
      })
  }
}