/* eslint-disable no-unused-vars */
import { IEnrollmentProgramSchedule } from 'src/types/program'
import { IRegistrationData } from 'src/types/registrationData'
import { instanceApi } from 'src/utils/axios'
import { CRUDService } from './core/crudService'

export class EnrollmentProgramScheduleService extends CRUDService<IEnrollmentProgramSchedule> {
  protected apiPath = '/api/app/enrollment-program-schedule'
  protected apiPathTerm = '/api/app/enrollment-term-class'

  getByChildAge(request: any[]) {
    return instanceApi.get<IEnrollmentProgramSchedule>(
      `${this.apiPath}/by-child-age`,
      { params: request }
    )
  }

  getTermsByProvider() {
    return instanceApi.get<any>(
      `${this.apiPath}/by-provider?forRegistration=true`
    )
  }

  getRegistrationDataByTerm(id: any) {
    return instanceApi.get<any>(`${this.apiPath}/${id}/registration-data`)
  }

  createNewTerm(request: any) {
    return instanceApi.post<IEnrollmentProgramSchedule>(
      `${this.apiPath}`,
      request
    )
  }

  updateTerm(id: string | number, request: any) {
    return instanceApi.put<IEnrollmentProgramSchedule>(
      `${this.apiPath}/${id}`,
      request
    )
  }

  deleteTerm(id: string | number) {
    return instanceApi.delete<IEnrollmentProgramSchedule>(
      `${this.apiPath}/${id}`
    )
  }

  createTermClass(request: any) {
    return instanceApi.post<any>(`${this.apiPathTerm}`, request)
  }

  updateTermClass(id: string | number, request: any) {
    return instanceApi.put<any>(`${this.apiPathTerm}/${id}`, request)
  }

  deleteTermClass(id: string | number) {
    return instanceApi.delete<any>(`${this.apiPathTerm}/${id}`)
  }

  getTermsAndTermsClasses() {
    return instanceApi.get<any>(`${this.apiPath}/with-details`)
  }

  getRegistrationData() {
    return instanceApi.get<IRegistrationData[]>(
      `${this.apiPath}/registration-data`
    )
  }

  getScheduleByProvider() {
    return instanceApi.get<any[]>(`${this.apiPath}/by-provider`)
  }

  getRegistrationsBySchedule(id: string) {
    return instanceApi.get<any[]>(`${this.apiPath}/${id}/registrations`)
  }

  getRegistrationsByChild(id: string, selectedFilter: string = 'current') {
    return instanceApi.get<any>(
      `${this.apiPath}/registrations-by-child/${id}?filter=${selectedFilter}`
    )
  }

  getRegistrationCountByChild(id: string) {
    return instanceApi.get<any>(
      `${this.apiPath}/registrations-count-by-child/${id}`
    )
  }

  // APIs for Term Wizard steps

  getTermWizardSetupByTermId(termId: string) {
    return instanceApi.get<any>(`${this.apiPath}/${termId}/term-wizard-setup`)
  }

  getTermWizardSetup() {
    return instanceApi.get<any>(`${this.apiPath}/term-wizard-setup`)
  }

  putTermWizardSetup(request: any) {
    return instanceApi.put<any>(`${this.apiPath}/term-wizard-setup`, request)
  }

  getTermBaseData() {
    return instanceApi.get<any>(`${this.apiPath}/term-base-data`)
  }

  getTermClasses() {
    return instanceApi.get<any>(`${this.apiPath}/term-classes`)
  }

  getTermList() {
    return instanceApi.get<any>(`${this.apiPath}/term-list`)
  }

  restoreDeletedTerm(termId: any) {
    return instanceApi.post<any>(`${this.apiPath}/${termId}/restore-term`)
  }
}
