import { IChatBotQuestion } from 'src/types/chat';
import { instanceApi } from 'src/utils/axios';
import { CRUDService } from './core/crudService';

export class ProviderChatQuestionService extends CRUDService<IChatBotQuestion> {

    protected apiPath = '/api/app/provider-chat-question';

    createMultiple(request?: IChatBotQuestion[]) {
        return instanceApi
            .post<IChatBotQuestion>(`${this.apiPath}/multiple-provider-chat-question`, request)
    }
}
