import { instanceApi } from 'src/utils/axios'
import { CRUDService } from './core/crudService'

export class ProviderUserTagService extends CRUDService<any> {
  protected apiPath = '/api/app/provider-user-tag'

  get() {
    return instanceApi.get<any>(`${this.apiPath}/all`)
  }

  putById(id: string, request: any) {
    return instanceApi.put<any>(`${this.apiPath}/${id}`, request)
  }

  deleteById(id: string) {
    return instanceApi.delete<any>(`${this.apiPath}/${id}`)
  }

  post(request: any) {
    return instanceApi.post<any>(this.apiPath, request)
  }
}
