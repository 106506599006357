import axios from 'axios'
import { oidcConfig } from '../config'

const instance = axios.create()
instance.interceptors.response.use(
  (response) => response,
  (error) =>
    Promise.reject(
      (error.response && error.response.data) || 'Something went wrong'
    )
)

export default instance

const authAxiosInstance = axios.create({
  baseURL: oidcConfig.stsAuthority,
  withCredentials: false,
  headers: {
    'Access-Control-Allow-Origin': '*',
    'Content-Type': 'application/x-www-form-urlencoded'
  }
})

authAxiosInstance.interceptors.response.use(
  (response) => response,
  (error) =>
    Promise.reject(
      (error.response && error.response.data) || 'Something went wrong'
    )
)

export const instanceApi = authAxiosInstance
