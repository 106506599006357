/* eslint-disable no-unused-vars */
import { IEnrollmentAgeOption } from 'src/types/enrollment-age-option'
import { instanceApi } from 'src/utils/axios'
import { CRUDService } from './core/crudService'

export class EnrollmentAgeOptionService extends CRUDService<IEnrollmentAgeOption> {
  protected apiPath = '/api/app/enrollment-age-option'

  getOptionByAgeGroup(ageGroup: number) {
    return instanceApi.get<IEnrollmentAgeOption[]>(
      `${this.apiPath}/by-age-group?ageGroup=${ageGroup}`
    )
  }
}
