import { pick } from 'lodash'
import { IEnrollmentSession } from 'src/types/session'
import { CRUDService } from './core/crudService'
import { EnrollmentClassService } from './enrollmentClassService'

export class EnrollmentSessionService extends CRUDService<IEnrollmentSession> {
  protected apiPath = '/api/app/enrollment-session'

  protected fillable = [
    'id',
    'providerId',
    'enrollmentProgramId',
    'enrollmentProgramScheduleId',
    'enrollmentClasses',
    'title',
    'hasCommonStartAndEndTime',
    'startHour',
    'endHour',
    'hasCommonAgeGroup',
    'hasCommonPricing',
    'hasDaysOfOperation',
    'enrollmentAgeId',
    'pricing',
    'orderNumber'
  ]

  create(request: Partial<IEnrollmentSession> = {}) {
    request = this.requestMap(request)
    return super.create(request)
  }

  update(id: string | number, request: Partial<IEnrollmentSession> = {}) {
    request = this.requestMap(request)
    return super.update(id, request)
  }

  mapItem(row: IEnrollmentSession) {
    if (row.daysOfOperation && typeof row.daysOfOperation === 'string') {
      row.daysOfOperation = row.daysOfOperation.split(',')
    } else {
      row.daysOfOperation = row.daysOfOperation || []
    }

    if (row.daysOfOperation) {
      row.hasDaysOfOperation = true
    }

    if (row.age) {
      row.hasAge = true
    }

    if (row.enrollmentClasses && row.enrollmentClasses?.length > 0) {
      row.hasClasses = true
    }

    return row
  }

  convertToRequestFormat(enrollmentSession: IEnrollmentSession) {
    const enrollmentClassService =
      EnrollmentClassService.getInstance<EnrollmentClassService>()
    return {
      ...pick(this.requestMap(enrollmentSession), this.fillable),
      enrollmentClasses: enrollmentSession.enrollmentClasses.map(
        (enrollmentClass) =>
          enrollmentClassService.convertToRequestFormat(enrollmentClass)
      )
    }
  }

  private requestMap(request) {
    request = Object.assign({}, request)

    if (!(request.id && isNaN(request.id))) {
      delete request.id
    }

    if (!request.hasDaysOfOperation) {
      request.daysOfOperation = ''
    }

    if (request.daysOfOperation instanceof Array) {
      request.daysOfOperation = request.daysOfOperation.join(',')
    }

    // if (!request.hasCommonStartAndEndTime) {
    //   request.startHour = ''
    //   request.endHour = ''
    // }

    if (!request.hasCommonAgeGroup) {
      request.enrollmentAgeId = null
    }

    if (!request.hasAge) {
      request.age = 0
    }

    return request
  }
}
