import { IPipeline, PipelineWithDetailsInput } from 'src/types/pipeline'
import { instanceApi } from 'src/utils/axios'
import { CRUDService } from './core/crudService'
import { PipelineStageService } from './pipelineStageService'

const pipelineStageService = PipelineStageService.getInstance()

export class PipelineService extends CRUDService<IPipeline> {
  protected apiPath = 'api/app/pipeline'

  getWithDetails(request: PipelineWithDetailsInput = {}) {
    return instanceApi
      .get<IPipeline>(`${this.apiPath}/with-details`, {
        params: request
      })
      .then((resp) => {
        resp.data = this.mapItem(resp.data)
        return resp
      })
  }

  mapItem(row: IPipeline) {
    if (row.pipelineStages) {
      row.pipelineStages = row.pipelineStages.map(pipelineStageService.mapItem)
    }
    return row
  }
}
