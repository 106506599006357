/* eslint-disable no-unused-vars */
import { IProviderSummary } from 'src/types/providerSummary'
import { CRUDService } from './core/crudService'
import { instanceApi } from 'src/utils'

export class ProviderSummaryService extends CRUDService<IProviderSummary> {
  protected apiPath = 'api/app/provider-summary'
  protected fillable = []

  get() {
    return instanceApi.get<IProviderSummary>(
      `${this.apiPath}?NextDaysToursCount=10`
    )
  }

  getProviderSummaryByDate(date) {
    return instanceApi.get<IProviderSummary>(
      `${this.apiPath}/by-date?date=${date}`
    )
  }
}
