import { pick } from 'lodash'
import { CRUDService } from './core/crudService'
import { IEnrollmentMedia, media } from 'src/types/enrollment-media'

export class EnrollmentMediaService extends CRUDService<IEnrollmentMedia> {
  protected apiPath = 'api/app/enrollment-media'

  protected fillable = [
    'title',
    'description',
    'imageUrl',
    'textBlockLeft',
    'orderNumber',
    'mediaType',
    'leftBlockSize',
    'providerId',
    'templateData',
    'enrollmentProgramId',
    'enrollmentMediaGroupId',
    'isDeleted',
    'enrollmentMediaColumns',
    'enrollmentMediaColumnsInput'
  ]

  create(request: Partial<IEnrollmentMedia> = {}) {
    request = this.requestMap(request)
    return super.create(request)
  }

  update(id: string | number, request: Partial<IEnrollmentMedia> = {}) {
    request = this.requestMap(request)
    return super.update(id, request)
  }

  mapItem = (row: IEnrollmentMedia) => {
    try {
      row.templateData = JSON.parse(row.templateData as string)
    } catch (error) {
      console.log({ error })
    }

    return {
      ...row,
      type: 'media' as media
    }
  }

  convertToRequestFormat(enrollmentMedia: IEnrollmentMedia) {
    return pick(this.requestMap(enrollmentMedia), [...this.fillable, 'id'])
  }

  requestMap(enrollmentMedia: IEnrollmentMedia) {
    const updatedEnrollmentMedia = Object.assign({}, enrollmentMedia)

    if (
      updatedEnrollmentMedia.templateData &&
      typeof updatedEnrollmentMedia.templateData === 'object'
    ) {
      updatedEnrollmentMedia.templateData = JSON.stringify(
        updatedEnrollmentMedia.templateData
      )
    }

    if (
      updatedEnrollmentMedia.enrollmentMediaColumns &&
      !updatedEnrollmentMedia.enrollmentMediaColumnsInput
    ) {
      updatedEnrollmentMedia.enrollmentMediaColumnsInput =
        updatedEnrollmentMedia.enrollmentMediaColumns

      delete updatedEnrollmentMedia.enrollmentMediaColumns;
    }

    return updatedEnrollmentMedia
  }
}
