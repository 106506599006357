import { useCallback, useState } from "react";

export interface SelectedItems {
  [key: string]: boolean,
}

export interface SelectedModels<T> {
  [key: string]: T,
}

export function arrayToSelection(value?: string[]): SelectedItems {
  if (!value || !value.length) {
    return {};
  }
  return value.reduce((acc, it) => {
    return (acc[it] = true, acc)
  }, {});
}

export function arrayToSelectionModel(value?: any[], getId = model => model.id): SelectedItems {
  if (!value || !value.length) {
    return {};
  }
  return value.reduce((acc, it) => {
    const id = getId(it);
    return (acc[id] = true, acc)
  }, {});
}


export function selectionToArray(selected: SelectedItems): string[] | null {
  const value = [];
  if (selected) {
    for (const key in selected) {
      if (selected[key]) {
        value.push(key);
      }
    }
    return value.length > 0 ? value : [];
  } else {
    return [];
  }
}

export interface MultiSelectableItemProps<T> {
  /** When true, display a checkbox the user can select in the first column of the item. */
  useSelect?: boolean,
  /** Select mode is active but selection mechanism is in disabled state. */
  selectDisabled?: boolean,
  /** Whether the user is currently selected. */
  selected?: boolean,
  /** Called when the check box is toggled. */
  onSelect?: (args: MultiSelectableOnSelectArgs<T>) => void,
}

export const defaultGetKey = (model, index) => model ? model.id : '' + index;

export interface MultiSelectableOnSelectArgs<T> {
  model: T,
  selected: boolean,
}

export type SelectItemEvent<T> =
  | "RESET"
  | {
    model: T,
    selected: boolean,
    only?: boolean,
  }

export interface MultiSelectableListProps<T> {
  /** When true, display a checkbox the user can select in the first column of the item. */
  useSelect?: boolean,
  /** Select mode is active but selection mechanism is in disabled state. */
  selectDisabled?: boolean,
  /** Whether the user is currently selected. */
  selected?: SelectedItems,
  /** Called when the check box is toggled. */
  onSelectItem?: (e: SelectItemEvent<T>) => void,
}

/** Check if the item is a list is selected */
export function isItemSelected(
  useSelect: boolean | undefined,
  key: string | undefined,
  selected: SelectedItems | undefined,
): boolean {
  if (!useSelect || !selected || !key) {
    return false;
  } else {
    return selected[key];
  }
}

export interface MultiSelectionChangeEvent<T> {
  itemEvent: SelectItemEvent<T>,
  selected: SelectedItems,
}

export interface UseMultiSelectionOpts<T> {
  initialValues?: SelectedItems,
  initialModels?: T[],
  onSelectChange?: (e: MultiSelectionChangeEvent<T>) => void,
  getId?: (model: any) => string,
}

export function useMultiSelection<T = any>({
  initialValues = {},
  initialModels = [],
  onSelectChange,
  getId = model => model.id,
}: UseMultiSelectionOpts<T> = {
    getId: model => model.id,
  }) {

  if (!initialValues) {
    for (const initialModel of initialModels) {
      initialValues[getId(initialModel)] = true;
    }
  }

  const [selection, setSelection] = useState<SelectedItems>(initialValues);

  const [models, setModels] = useState<T[]>(initialModels);

  const onSelectItem = useCallback((event: SelectItemEvent<T>) => {

    if (event === 'RESET') {
      const nextSelection = {};
      setModels([]);
      setSelection(nextSelection);
      setModels([]);
      onSelectChange && onSelectChange({ itemEvent: event, selected: nextSelection });
      return;
    }

    const { model, selected, only = false } = event;

    const nextSelection = {
      ...(only ? {} : selection),
      [getId(model)]: selected,
    };

    if (only) {
      setModels([model]);
    } else {
      setModels((items) => {
        if (selected) {
          const found = items.filter((m) => getId(m) === getId(model));
          if (found.length === 0) {
            items.push(model);
          }
        } else {
          items = items.filter((m) => getId(m) !== getId(model));
        }
        return items;
      });
    }

    setSelection(nextSelection);
    onSelectChange && onSelectChange({ itemEvent: event, selected: nextSelection });
  }, [selection, setSelection, setModels, getId, onSelectChange]);

  return {
    useSelect: true,
    selected: selection,
    models,
    onSelectItem,
  };
}
