import { ISlideshow } from 'src/types/slideshow'
import { instanceApi } from 'src/utils/axios'
import { CRUDService } from './core/crudService'

export class SlideshowService extends CRUDService<ISlideshow> {
  protected apiPath = '/api/app/slideshow'

  protected fillable = [
    'providerId',
    'providerName',
    'title',
    'description',
    'thumbnailImageURL',
    'status',
    'type',
    'isPublished',
    'slides',
    'isDeleteSlides'
  ]

  getSlideShowByType(request: any) {
    return instanceApi.get<any>(`${this.apiPath}/by-type`, { params: request })
  }

  updateStatus(id: string, status: 'publish' | 'unpublish') {
    return instanceApi.post(`${this.apiPath}/${id}/${status}`, {})
  }
}
