/* eslint-disable no-unused-vars */
import {
  IProviderTemplate,
  IProviderEmailTemplate,
  IProviderEmailTemplateDetails,
  ITemplatesByProviderType,
  ISendEmailInstanceInput,
  ISendTestEmail,
  ISendEmailContent,
  IUpdateTemplateStatus
} from 'src/types/email-template'
import { CRUDService } from './core/crudService'
import { instanceApi } from 'src/utils'

export class ProviderTemplateService extends CRUDService<IProviderTemplate> {
  protected apiPath = '/api/app/provider-template'
  protected PreviewApiPath = '/api/app/provider'

  getTemplatesByProvider(type: ITemplatesByProviderType) {
    return instanceApi.get<IProviderEmailTemplate[]>(
      `${this.apiPath}/templates-by-provider?type=${type}`
    )
  }

  getTemplateById(id: string) {
    return instanceApi.get<IProviderEmailTemplateDetails>(
      `${this.apiPath}/${id}/template-by-provider`
    )
  }

  getTemplateByAlias(alias: string, tourId?: string, parentId?: string) {
    const apiDetails = tourId
      ? parentId
        ? `${this.apiPath}/template-by-provider?alias=${alias}&parentId=${parentId}&tourId=${tourId}`
        : `${this.apiPath}/template-by-provider?alias=${alias}&tourId=${tourId}`
      : parentId
      ? `${this.apiPath}/template-by-provider?alias=${alias}&parentId=${parentId}`
      : `${this.apiPath}/template-by-provider?alias=${alias}`

    return instanceApi.get<IProviderEmailTemplateDetails>(apiDetails)
  }

  getTemplateSectionByAlias(request: {
    postmarkAlias: string
    settingsJson?: string
    htmlBody?: string
  }) {
    return instanceApi.get<string>(`${this.apiPath}/template-section`, {
      params: request
    })
  }

  sendEmailInstance(request: ISendEmailInstanceInput) {
    return instanceApi.post(`${this.apiPath}/send-instance`, request)
  }

  sendEmailContent(request: ISendEmailContent) {
    return instanceApi.post(`${this.apiPath}/send-content`, request)
  }

  updateTemplate(request: IProviderTemplate) {
    return instanceApi.put<IProviderEmailTemplateDetails>(
      `${this.apiPath}/provider-template-content`,
      request
    )
  }

  updateTemplateCompleteStatus(request: IUpdateTemplateStatus) {
    return instanceApi.post<any>(`${this.apiPath}/update/completed`, request)
  }

  previewTemplate(postmarkAlias: string) {
    return instanceApi.get<IProviderEmailTemplateDetails>(
      `${this.PreviewApiPath}/postmark-html-template?alias=${postmarkAlias}`
    )
  }

  sendTestByProvider(request: ISendTestEmail) {
    return instanceApi.post<any>(
      `${this.apiPath}/send-test-by-provider`,
      request
    )
  }

  getProviderTemplateLayouts() {
    return instanceApi.get<any>(`${this.apiPath}/layouts`)
  }

  getProviderTemplateLayout(layoutAlias: string) {
    return instanceApi.get<any>(`${this.apiPath}/layout?alias=${layoutAlias}`)
  }

  updateProviderTemplateLayout(layoutAlias: string) {
    return instanceApi.post<any>(
      `${this.apiPath}/change-layout-template?alias=${layoutAlias}`
    )
  }

  updateTemplateStatus(statusRequest: { id: string; disabled: boolean }) {
    return instanceApi.put<any>(`${this.apiPath}/data`, statusRequest)
  }
}
