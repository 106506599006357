import { IPagination, IPaginationRequest } from 'src/types/core'
import { IAlbum } from 'src/types/album'
import { instanceApi } from 'src/utils/axios'
import { CRUDService } from './core/crudService'

export class AlbumService extends CRUDService<IAlbum> {
  protected apiPath = 'api/app/album'

  getAlbumsForProvider(id?: string) {
    return instanceApi.get<IPagination<IAlbum>>(
      `${this.apiPath}/${id}/albums-for-provider`
    )
  }

  getMediaFilesForAlbum(id: string, request?: IPaginationRequest) {
    return instanceApi.get<IPagination<IAlbum>>(
      `${this.apiPath}/${id}/media-files-for-album`,
      { params: request }
    )
  }

  moveMediaFilesBetweenAlbumns(
    sourceAlbumId: string,
    destinationAlbumId: string,
    mediaFilesId: string[]
  ) {
    return instanceApi.post<IPagination<IAlbum>>(
      `${this.apiPath}/move-media-files-list`,
      {
        sourceAlbumId,
        destinationAlbumId,
        mediaFilesId
      }
    )
  }

  removeSelectedFilesFromAlbum(albumId: string, mediaFilesId: string[]) {
    return instanceApi.post<IPagination<IAlbum>>(
      `${this.apiPath}/remove-media-files-list`,
      {
        albumId,
        mediaFilesId
      }
    )
  }
}
