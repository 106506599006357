/* eslint-disable no-unused-vars */
import { ISubscriptionTransaction } from 'src/types/subscriptionTransaction'
import { Service } from './core/service'
import { instanceApi } from 'src/utils/axios'

export class SubscriptionTransactionService extends Service {
  protected apiPath = 'api/app/subscription-transaction'

  getPaymentHistory() {
    return instanceApi.get<ISubscriptionTransaction>(`${this.apiPath}`)
  }
}
