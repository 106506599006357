/* eslint-disable no-unused-vars */
import { IEnrollmentClassOption } from 'src'
import { ICalendarEventParentDetails } from './calendarEvent'
import { IChild } from './child'
import { IBaseEntity } from './core'
import { IEnrollmentClass } from './enrollment-class'
import { IFamily } from './families'
import { IParent } from './parent'
import { IPipelineStage } from './pipeline'
import { IProgram } from './program'

export interface IDeal extends IBaseEntity {
  title?: string
  familyId?: string
  family?: IFamily
  pipelineStageId?: string
  pipelineStage?: IPipelineStage
  providerId?: string
  dealOrder?: number
  tourStatus?: string
  tourDate?: number | string
  tourTime?: number | string
  note?: number
  enrollmentProgramId?: number
  enrollmentProgram?: IProgram
  source?: DealSourceEnum
  parentsId?: string[]
  parents?: IDealParent[]
  childsId?: string[]
  childs?: IDealChild[]
  totalPrice?: number
  type?: number
  status?: number
  isNew?: boolean
  isOverdue?: boolean
  pastTour?: boolean
  isCancelled?: boolean
  isRescheduled?: boolean
  parentGreeting?: string
  archivedLead?: boolean
  futureLead?: boolean
  registerLead?: boolean
  initialView?: string
  baseStatus?: number
  deletionDate?: Date
  calendarEventParent?: ICalendarEventParentDetails
}

export enum DealSourceEnum {
  DIRECT_CALL = 'Direct call',
  DIRECT_EMAIL = 'direct email',
  ONEVILLAGE = 'OneVillage',
  FACEBOOK = 'Facebook',
  WEBSITE = 'Website',
  CALL = 'Call',
  SMS = 'SMS',
  WALK_IN = 'Walk in',
  EMAIL = 'Email'
}

export enum DealTourStatusEnum {
  COMPLETED = 'Completed',
  PLANED = 'Planed',
  NO_SHOW = 'No Show'
}

export enum PipelineStageEnum {
  CONSIDERING = 'Considering',
  TOUR_SCHEDULED = 'Tour Scheduled',
  EARLY_INTEREST = 'Early Interest',
  REGISTERED = 'Register',
  Enrolled = 'Enrolled'
}

export interface IDealParent extends IBaseEntity {
  dealId?: string
  parentId?: string
  parent?: IParent
}

export interface IDealChild extends IBaseEntity {
  dealId?: string
  providerId?: string
  childId?: string
  note?: string
  enrollmentClasses?: IDealChildEnrollmentClass[]
  enrollmentClassesId?: string[]
  child?: IChild
  registrationData?: any
}

export interface IDealRegisterChild extends IBaseEntity {
  childId?: string
  providerId?: string
  enrollmentClassOptionsId?: string
}

export interface IDealChildEnrollmentClass extends IBaseEntity {
  dealChildId?: string
  providerId?: string
  enrollmentClassId?: string
  enrollmentClass?: IEnrollmentClass
  note?: string
  enrollmentClassOptionsId: string
  enrollmentClassOptions: IEnrollmentClassOption
}

export interface ICreateDealChildsInput {
  dealId?: string
  childsId?: string[]
}

export interface IUpdateDealChildsInput {
  dealId?: string
  childsId?: string[]
}

export interface ICreateDealParentsInput {
  dealId?: string
  parentsId?: string[]
}

export interface IUpdateDealParentsInput {
  dealId?: string
  parentsId?: string[]
}

export interface ICreateDealChildEnrollmentClassInput {
  dealChildId?: string
  enrollmentClassesId?: string[]
}

export interface IUpdateDealChildEnrollmentClassInput {
  dealChildId?: string
  enrollmentClassesId?: string[]
}

export interface ICreateUpdateClassesListInput {
  childId?: string
  dealId?: string
  providerId?: string
  enrollmentClassesId?: string[]
}

export interface ICreateDealWithDetailsInput {
  deal?: {
    dealId?: string
    title?: string
    pipelineStageId?: string
    providerId?: string
    dealOrder?: 0
    familyId?: string
    source?: string
    tourDate?: string
    note?: string
    enrollmentProgramId?: string
    enrollmentProgramScheduleId?: string
    parentsId?: string[]
    childsId?: string[]
    type?: number
    status?: number
    isNew?: boolean
    isOverdue?: boolean
    isCancelled?: boolean
    isRescheduled?: boolean
    archivedLead?: boolean
    registerLead?: boolean
    futureLead?: boolean
    initialView?: string
  }
  parent?: {
    firstName?: string
    lastName?: string
    email?: string
    phone?: string
    sendInviteEmail?: boolean
  }
  dealChilds?: IDealChildMap[]
}

export interface IRegisterChildrenTermInput {
  dealId?: string
  termId?: string
  startDate?: string
  note?: string
  registrationType?: number
}

export interface IDealChildMap {
  childId?: string
  note?: string
  firstName?: string
  dateOfBirth?: string
  enrollmentClassesId?: string[]
}

export interface IRegisterDealChildInput {
  dealChildId?: string
  enrollmentClassesOptionId?: string
  startDate?: string
  note?: string
  registrationType?: number
}
