import React, { useCallback } from 'react';
import { TextField } from 'formik-material-ui';
import { Box, Grid } from '@material-ui/core';
import { Field, Form, Formik } from 'formik';
import { FormikObserver } from '../FormikObserver';
import { object, string } from 'yup';


const validationSchema = object().shape({
  currentPassword: string()
    .required(),
  newPassword: string()
    .required(),
  confirmPassword: string()
    .required(),
});

const defaultFormValue = {
  currentPassword: '',
  newPassword: '',
  confirmPassword: '',
}


export const ChangePasswordField = ({
  form: { setFieldValue },
  field: {
    name,
    value
  },
  onSubmit
}) => {

  const handleOnChange = useCallback(
    ({ currentPassword, newPassword }) => {
      setFieldValue(name, { currentPassword, newPassword })
    },
    [setFieldValue, name],
  )

  return (
    <Formik
      initialValues={Object.assign({}, value, defaultFormValue)}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({ handleSubmit }) => (
        <Form onSubmit={handleSubmit} >
          <FormikObserver onChange={handleOnChange} />
          <Box mb={4}>
            <Grid
              container
              spacing={3}
            >
              <Grid
                item
                sm={12}
              >
                <Field
                  fullWidth
                  type="password"
                  component={TextField}
                  label="Current Password"
                  name="currentPassword"
                  required
                />
              </Grid>
              <Grid
                item
                sm={6}
              >
                <Field
                  fullWidth
                  type="password"
                  component={TextField}
                  label="New Password"
                  name="newPassword"
                  required
                />
              </Grid>
              <Grid
                item
                sm={6}
              >
                <Field
                  fullWidth
                  type="password"
                  component={TextField}
                  label="Repeat Password"
                  name="confirmPassword"
                  required
                />
              </Grid>
            </Grid>
          </Box>
        </Form>
      )}
    </Formik>
  );
};


export default ChangePasswordField;