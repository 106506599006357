import { IParentProvider, IRelationUpdateInput } from 'src/types/parent'
import { instanceApi } from 'src/utils'
import { CRUDService } from './core/crudService'
import { IPagination } from 'src/types/core'

export class ParentProviderService extends CRUDService<IParentProvider> {
  protected apiPath = '/api/app/parent-provider'

  getProvidersByParent() {
    return instanceApi.get<IPagination<IParentProvider>>(
      `${this.apiPath}/by-parent`
    )
  }

  getParentMembers(threadParentType: number) {
    return instanceApi.get<any>(
      `${this.apiPath}/parents?threadParentType=${threadParentType}`
    )
  }

  updateRelationByParent(id: string, request: IRelationUpdateInput) {
    return instanceApi.put<IParentProvider>(
      `${this.apiPath}/${id}/by-parent`,
      request
    )
  }
}
