import { IBaseEntity, IPaginationRequest } from './core'

export interface IStory extends IBaseEntity {
  [x: string]: any
  providerId?: string
  name?: string
  description?: string
  isPublished?: boolean
  thumbnailImageURL?: string
  streamVideoURL?: string
  videoURL?: string
  storyAudioFiles?: IStoryAudioFile[]
  storyMediaFiles?: IStoryMediaFile[]
}

export interface IGetStoryRequest extends IPaginationRequest {
  Sorting?: string
}

export interface IMediaFile extends IBaseEntity {
  [x: string]: any
  title?: string
  path?: string
  thumbnail?: string
  isVideo?: boolean
  duration?: number
  layoutType?: LayoutTypeEnum
  albumId?: string
}

export interface IMediaFileWithText extends IMediaFile {
  text: string
}

export interface IGetMediaFilesById extends IPaginationRequest {
  AlbumId?: string;
  Sorting?: string;
}

export interface ISearchMediaFileInput extends IPaginationRequest {
  Sorting?: string
  search?: string
  isIncludeVideos?: boolean
  isIncludeImages?: boolean
}

export enum LayoutTypeEnum {
  LEFT = 0,
  CENTER = 1,
  RIGHT = 2
}

export interface IStoryMediaFile extends IBaseEntity {
  storyId?: string
  mediaFileId?: string
  providerId?: string
  fileOrder?: string
  mediaFile?: IMediaFile
}

export interface IStroyThumbnailFile extends IBaseEntity {
  path?: string
  providerId?: string
}

export interface IAudioFile extends IBaseEntity {
  trackTitle?: string
  path?: string
  duration?: string
  providerId?: string
  typeMusic?: MusicTypeEnum
  isRecorded?: boolean
  isNarration?: boolean
}

export interface ISearchAudioFileInput extends IPaginationRequest {
  Sorting?: string
  search?: string
  typeMusic?: MusicTypeEnum
  withNarration?: boolean
}

export enum MusicTypeEnum {
  ANY = 0,
  INSTRUMENTAL = 1,
  CALM = 2,
  UPBEAT = 3
}

export interface IStoryAudioFile extends IBaseEntity {
  storyId?: string
  audioFileId?: string
  providerId?: string
  fileOrder?: number
  audioFile?: IAudioFile
}

export interface ITour extends IBaseEntity {
  providerId?: string
  providerName?: string
  title?: string
  description?: string
  thumbnailImageURL?: string
  status?: TourStatusEnum
  isPublished?: boolean
  tourStories: ITourStory[]
}

export enum TourStatusEnum {
  UNPUBLISHED = 0,
  PUBLISHED = 1
}

export interface ITourStory extends IBaseEntity {
  providerId?: string
  tourId?: string
  storyId?: string
  fileOrder?: number
  story: IStory
}

export interface VideoSrc {
  src?: string
  thumbnail?: string
  streamURL?: string
}
export interface ImageSrc {
  src: string
  thumbnail?: string
}

export type PhotoAndVideo = {
  id?: string
  src?: string
  type?: 'video' | 'image'
  thumbnailUrl?: string
  duration?: number
}

export interface AddTextAndOrderSlide {
  id?: string
  title?: string
  layoutType?: LayoutTypeEnum
  thumbnailUrl?: string
  duration?: number
}

export interface IAudio {
  id?: string
  title?: string
  type?: string
  duration?: number
  src?: string
}

export interface IVideo {
  id?: string
  title?: string
  duration?: number
  description?: string
  src?: string
  thumbnail?: string
  streamURL?: string
}

export interface IEncodeMedia {
  url?: string
  thumbnail?: string
  duration?: number
}

export interface IAddNarrationSoundInput {
  storyId?: string
  audioFileId?: string
}

export interface IGenerateAudioData {
  path: string
  duration: string
  trackTitle: string
}

export interface ISpeechVoiceData {
  DisplayName: string
  ShortName: string
  Gender: string
  Locale: string
  VoiceType: string
  Status: string
}

export enum LibraryViewEnum {
  GRID = 'grid',
  LIST = 'list'
}

export enum FolderSortByEnum {
  NAME = 'name',
  CREATED = 'creationTime',
  LAST_MODIFIED = 'lastModificationTime'
}

export enum FileSortByEnum {
  NAME = 'title',
  CREATED = 'creationTime',
  LAST_MODIFIED = 'lastModificationTime'
}

export interface ILibraryFilter {
  filesType?: 'video' | 'image' | 'all'
  orderBy?: FileSortByEnum
  order?: 'DESC' | 'ASC'
}

export interface ISortList {
  orderBy?: FileSortByEnum | FolderSortByEnum
  order?: 'DESC' | 'ASC' | 'asc' | 'desc'
}

export enum CollectionSortEnum {
  DESC = 'Most recent images first',
  ASC = 'Oldest images first'
}
