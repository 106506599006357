import { pick } from "lodash";
import { IPagination, IPaginationRequest } from "src/types/core";
import { instanceApi } from "src/utils/axios";
import { Service } from "./service";

export abstract class CRUDService<T> extends Service {
  protected abstract apiPath: string;
  protected fillable: string[] = [];

  getAll(request: IPaginationRequest = {}) {
    return instanceApi.get<IPagination<T>>(`${this.apiPath}`, {
      params: request
    })
      .then((resp) => {
        resp.data.items = resp.data.items.map((file) => this.mapItem(file));
        return resp
      });
  }

  get(id: string | number, request: Partial<T> = {}) {
    return instanceApi.get<T>(`${this.apiPath}/${id}`, {
      params: request
    })
      .then((resp) => {
        resp.data = this.mapItem(resp.data);
        return resp;
      });
  }

  create(request: Partial<T> = {}) {
    if (this.fillable.length > 0) {
      request = pick(request, this.fillable);
    }

    return instanceApi.post<T>(`${this.apiPath}`, request)
      .then((resp) => {
        resp.data = this.mapItem(resp.data);
        return resp;
      });
  }

  update(id: string | number, request: Partial<T> = {}) {
    if (this.fillable.length > 0) {
      request = pick(request, this.fillable);
    }
    return instanceApi.put<T>(`${this.apiPath}/${id}`, request)
      .then((resp) => {
        resp.data = this.mapItem(resp.data);
        return resp;
      })
  }

  delete(id: string | number) {
    return instanceApi.delete<T>(`${this.apiPath}/${id}`)
  }

  mapItem(row: T) {
    return row;
  }
}