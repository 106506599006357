export const toDuration = (duration: number | string) => {
  const secNum = parseInt(duration as string, 10);
  const hours: any = Math.floor(secNum / 3600);
  const minutes: any = Math.floor((secNum - (hours * 3600)) / 60);
  const seconds: any = secNum - (hours * 3600) - (minutes * 60);
  const times = [];
  if (hours > 0){
    times.push(hours < 10 ? "0" + hours : hours);
  }
  times.push(minutes < 10 ? "0" + minutes : minutes);
  times.push(seconds < 10 ? "0" + seconds : seconds);
  return times.join(':');
};

