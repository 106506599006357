/* eslint-disable no-unused-vars */
import { IBaseEntity } from './core'

export interface IEmailTemplate extends IBaseEntity {
  title?: string
  description?: string
  content?: string
  isActive?: string
  providerId?: string
}

export interface IPostmarkHtmlTemplate {
  isActive?: boolean
  alias?: PostmarkHtmlTemplateEnum
  description: string
  layoutTemplate?: string
  name?: string
  templateId: number
}

export enum PostmarkHtmlTemplateEnum {
  TOUR_SCHEDULED = 'schedule_tour_confirmation',
  WELCOME = 'invite-parent-registration'
}

export interface IUpdatePostmarkHtmlTemplateInput {
  alias?: string
  htmlBody?: string
}

export interface IUpdateProviderEmailSettings {
  emailConnect: boolean
  emailRelay: boolean
}

export interface ISendEmailInput {
  emailsTo?: string[]
  body?: string
  subject?: string
  threadId?: string
}

export interface ISendCommonEmailInput {
  parentIds?: string[]
  greeting?: string
  dealId?: string
}

export interface ITemplateVariable {
  name?: string
  group?: string
  value?: string
}

export interface ISendTestEmail {
  postmarkAlias?: string
  testRenderModel?: string
}

export interface IProviderTemplate extends IBaseEntity {
  providerId?: string
  name?: string
  htmlContent?: string
  postmarkAlias?: string
  status?: string
}

export enum ITemplatesByProviderType {
  MY_TEMPLATE = 0,
  EMAIL_TEMPLATE = 1,
  EMAIL_LAYOUT = 2,
  NOTIFICATION = 3
}

export interface ITemplate {
  id?: string
  creationTime?: string
  creatorId?: string
  lastModificationTime?: string
  providerId?: string
  name?: string
  htmlContent?: string
}

export interface IProviderEmailTemplate {
  id?: string
  name?: string
  type?: string
  postmarkAlias?: string
  providerId?: string
  htmlContent?: string
}

export interface IProviderTemplateContentOV {
  id?: string
  scenario?: string
  content?: string
}

export interface IProviderTemplateContent {
  id?: string
  name?: string
  providerEdit?: string
  content?: string
  contentType?: string
  oneVillageTemplateSectionContent?: IProviderTemplateContentOV[]
}

export enum IContentType {
  PROVIDER_INDIRECT_CONTENT = 3,
  ONE_VILLAGE_CONTENT = 1,
  PROVIDER_DIRECT_CONTENT = 2
}
export interface IProviderMasterTemplateContent {
  id?: string
  order?: number
  providerTemplateContent?: IProviderTemplateContent
  contentType?: IContentType
  providerId?: string
  providerTemplateId?: string
  providerTemplateContentId?: string
  name?: string
  renderedContent?: string
  triggerDescription?: string
  hasTriggerSetting?: boolean
}

export interface ISendEmailInstanceInput {
  parentId?: string
  postmarkAlias?: string
  providerTemplateContent?: { content?: string }[]
  calendarEventParentId?: string
}

export interface ISendEmailContent {
  parentId?: string
  postmarkAlias?: string
  subject?: string
  htmlContent?: string
  tourId?: string
}

export interface IProviderEmailTemplateDetails extends IProviderEmailTemplate {
  providerTemplatesContentTemplate?: IProviderMasterTemplateContent[]
}

export interface ITemplateContentInput {
  content: string
  providerTemplateId: string
  settingsJson: string
  templateVariable: string
  usageType: number
}

export interface ITemplateContent extends IBaseEntity {
  providerId?: string
  name?: string
  type?: string
  maxCharacters?: number
  providerTemplateId?: string
  settingsJson?: string
  templateVariable?: string
}

export interface IProviderSectionTemplate extends IBaseEntity {
  minSubscription?: string
  usageType?: string
  contentType?: string
  description?: string
  content?: string
  name?: string
  contentGroup?: string
  strapiId?: number
}

export interface ISectionTemplate extends IBaseEntity {
  minSubscription?: string
  usageType?: string
  contentType?: string
  description?: string
  contentHTML?: string
  name?: string
  contentGroup?: string
  strapiId?: number
  contentPlain?: string
  sampleData?: string
}

export interface IPrimaryMarketingTemplate {
  primary: ISectionTemplate[]
  marketing: ISectionTemplate[]
}

export interface IUpdateTemplateStatus {
  layoutUpdate: boolean
  postmarkAlias?: string
}
