import {
  Avatar,
  Box,
  Button,
  Grid,
  LinearProgress,
  List,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  makeStyles,
  Typography
} from "@material-ui/core";
import clsx from "clsx";
import React, { FC, useCallback, useState } from "react";
import { Theme } from "src/theme";
import AttachFileIcon from '@material-ui/icons/AttachFile';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import { bytesToSize } from "src/utils/bytesToSize";
import { FileUploadDropZone, FileUploadEvent } from "./FileUploadDropZone";
import { UseFileUploads } from "src/utils/fileUploads";
import { first, uniqueId } from "lodash";


export interface UploadedFiles {
  id?: string;
  name?: number;
  size?: number;
}

export interface SelectedFile extends File {
  id: string;
}

export interface FileUploadProps extends UseFileUploads {
  className?: string;
  onUpload?: (files: File[]) => void
  onDeleteFile?: (files: File | UploadedFiles) => void,
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  uploadFileAction: {
    textAlign: 'right',
  },
  uploadFileErrorMesage: {
    color: theme.palette.error.main
  },
  removeAllButton: {
    marginRight: theme.spacing(2),
  },
  browseButton: {
    cursor: 'pointer',
  },
  uploadImage: {
    width: '100%',
    '& img': {
      width: '100%',
    }
  }
}));

export const FileUpload: FC<FileUploadProps> = ({
  className,
  multiple = true,
  onUpload,
  isUploading,
  onDeleteFile,
  getProgressValue,
  isInProgress,
  isEncoding,
  getErrorMessage
}) => {
  const classes = useStyles();
  const [files, setFiles] = useState<SelectedFile[]>([]);

  const handleFileUpload = useCallback(
    (event: FileUploadEvent) => {
      const uploadedFiles: SelectedFile[] = Array.from(event.value).map((file: any) => {
        file.id = uniqueId();
        return file;
      });
      let allFiles = files.concat(uploadedFiles);
      if (!multiple) {
        allFiles = [first(uploadedFiles)];
      }
      setFiles(allFiles);
    }, [setFiles, files, multiple]
  );

  const handleRemoveFile = useCallback(
    (index) => () => {
      const file = files[index];
      files.splice(index, 1);
      setFiles(Object.assign([], files));
      onDeleteFile && onDeleteFile(file);
    },
    [files, setFiles, onDeleteFile],
  )

  const handleRemoveAllFile = useCallback(
    () => {
      setFiles([]);
    },
    [setFiles],
  )

  const handleUpload = useCallback(
    () => {
      onUpload && onUpload(files);
    },
    [onUpload, files],
  )

  return (
    <Grid
      className={clsx(classes.root, className)}
      direction="column"
      container
      spacing={4}
    >
      <Grid item>
        <FileUploadDropZone
          multiple={multiple}
          onUpload={handleFileUpload}
        >
          <Grid
            container
            alignItems="center"
            alignContent="center"
            spacing={4}
          >
            <Grid
              item
              xs={3}
              className={classes.uploadImage}
            >
              <img
                alt="Create new video"
                src={'/static/images/video-tour/upload-file.png'}
              />
            </Grid>
            <Grid
              item
              xs={9}
            >
              <Typography variant='h3'>Upload Files</Typography>
              <Typography
                color="textSecondary"
              >
                Drop files here or click &nbsp;
                <Typography
                  className={classes.browseButton}
                  variant="button"
                  color="textPrimary"
                >
                  browse
              </Typography>
              &nbsp; thorough your machine
            </Typography>
            </Grid>
          </Grid>
        </FileUploadDropZone>
      </Grid>
      {files.length > 0 && (
        <Grid item>
          <List>
            {files.map((file, index) => (
              <ListItem key={index}>
                <ListItemAvatar>
                  <Avatar>
                    <AttachFileIcon />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText primary={file.name} secondary={bytesToSize(file.size)} />
                <ListItemSecondaryAction>
                  <Grid
                    alignItems={"center"}>
                    {/* Uncomment below if need to show text before action */}
                    {/* {!!getErrorMessage(file.id) && (<span className={classes.uploadFileErrorMesage} >{getErrorMessage(file.id)}</span>)} */}
                    {!getErrorMessage(file.id) && ((isInProgress && isInProgress(file.id)) || isUploading) ? (
                      <Box width={100}>
                        {
                          isEncoding(file.id) ? <LinearProgress /> : <LinearProgress
                            variant="determinate"
                            value={getProgressValue(file.id)}
                          />
                        }
                      </Box>
                    ) :
                      (<Button
                        onClick={handleRemoveFile(index)}
                        color="primary"
                      >
                        Delete
                      </Button>)
                    }
                  </Grid>
                </ListItemSecondaryAction>
              </ListItem>
            ))}
          </List>
          <Box
            className={classes.uploadFileAction}
            mt={3}
          >
            {!isUploading ? (
              <Button
                onClick={handleRemoveAllFile}
                className={classes.removeAllButton}
                color="secondary"
              >
                Remove all files
              </Button>) : ''
            }
            <Button
              disabled={isUploading}
              color="primary"
              id="fileUpload"
              variant="contained"
              onClick={handleUpload}
              startIcon={<CloudUploadIcon />}
            >
              Upload
            </Button>

          </Box>
        </Grid>
      )
      }
    </Grid>
  )
};

