import { pick } from 'lodash';
import { IAddress, IAddressZipcode } from 'src/types/address';
import { IPagination } from 'src/types/core';
import { instanceApi } from 'src/utils/axios';
import { CRUDService } from './core/crudService';

export class AddressService extends CRUDService<IAddress> {
  protected apiPath = '/api/app/address';

  getAddressByZipcode(search: string) {
    return instanceApi.get<IPagination<IAddressZipcode>>(
      `${this.apiPath}/search-by-zipcode?search=${search}`
    );
  }


  update(id: string | number, request: Partial<IAddress> = {}) {
    if (this.fillable.length > 0) {
      request = pick(request, this.fillable);
    }
    return instanceApi.put<IAddress>(`${this.apiPath}?id=${id}`, request)
      .then((resp) => {
        resp.data = this.mapItem(resp.data);
        return resp;
      })
  }
}
