/* eslint-disable no-unused-vars */
import { IBaseEntity, IPaginationRequest } from './core'

export interface ITimeline extends IBaseEntity {
  tenantId?: string
  providerId?: string
  familyId?: string
  event?: TimelineEventEnum
  type?: TimelineTypeEnum
  person?: string
  description?: any
  creationTimeUTC?: Date
  triggeringActor?: number
  feature?: string
}

export interface INewTimeline extends IBaseEntity {
  providerId?: string
  familyId?: string
  timelineReference?: ITimelineReference
  timelineReferenceId?: string
  creationTimeUTC?: Date
}

export interface IGetTimelineByFamilyInput extends IPaginationRequest {
  FamilyId: string
}

export interface ITimelineByFilter {
  TimelineFeature?: string
  UseCase?: string
  Event?: string
  Author?: number
  FamilyId?: string
}

export interface ITimelineItemGroup {
  date: string
  timelineItems: ITimeline[]
}

export enum TimelineEventEnum {
  FAMILY_UPDATED = 'Family updated',
  ADDED_CHILD = 'Added child',
  UPDATED_CHILD = 'Updated child',
  ADDED_PARENT = 'Added parent',
  UPDATED_PARENT = 'Updated parent',
  DEAL_CREATED = 'Deal created',
  DEAL_STATUS_CHANGE = 'Deal status change',
  EMAIL_SENT = 'Email sent',
  ADDED_FAMILY_AND_PRIMARY_PARENT = 'Added Family/Primary Parent',
  PARENT_NOTIFICATION = 'Parent Notification'
}

export enum TimelineTypeEnum {
  MANUAL = 'Manual'
}

export interface ITimelineReference {
  strapiId?: number
  scope?: string
  feature?: string
  subFeature?: string
  event: string
  trigger: string
  entity: string
  providerPortalUI: string
  providerNotify: string
  context: string
  providerEmailContent: IProviderEmailContent
}

export interface IProviderEmailContent {
  contentHtml?: string
  contentPlain?: string
  postmarkTemplateAlias?: string
  emailSubject?: string
}
