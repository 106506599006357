import { CRUDService } from './core/crudService'
import { IEnrollmentMediaTemplate } from 'src/types/enrollment-media';

export class EnrollmentTemplateService extends CRUDService<IEnrollmentMediaTemplate> {
  protected apiPath = '/api/app/enrollment-media-template'

  mapItem(item: IEnrollmentMediaTemplate) {

    // if (item.enrollmentMedia) {
    //   try {
    //     item.enrollmentMedia = JSON.parse(item.enrollmentMedia as any);
    //   } catch (error) {

    //   }
    // } else 
    if (item.htmlContent) {
      try {
        item.enrollmentMedia = JSON.parse(item.htmlContent);
      } catch (error) {

      }
    }

    return item;
  }
}
