/* eslint-disable no-unused-vars */
import { IPipeline, PipelineWithDetailsInput } from 'src/types/pipeline'
import { IProviderDealFilter } from 'src/types/providerDealFilter'
import { instanceApi } from 'src/utils/axios'
import { CRUDService } from './core/crudService'
import { PipelineStageService } from './pipelineStageService'

const pipelineStageService = PipelineStageService.getInstance()

export class ProviderDealFilterService extends CRUDService<IPipeline> {
  protected apiPath = 'api/app/provider-deal-filter'

  getByProvider(request: PipelineWithDetailsInput = {}) {
    return instanceApi
      .get<IPipeline>(`${this.apiPath}/by-provider`, {
        params: request
      })
      .then((resp) => {
        resp.data = this.mapItem(resp.data)
        return resp
      })
  }

  mapItem(row: IPipeline) {
    if (row.pipelineStages) {
      row.pipelineStages = row.pipelineStages.map(pipelineStageService.mapItem)
    }
    return row
  }

  updateFilterByProvider(id: string, request: IProviderDealFilter) {
    return instanceApi.put<IPipeline>(
      `${this.apiPath}/${id}/by-provider`,
      request
    )
  }
}
