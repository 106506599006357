/* eslint-disable no-debugger */
import axios from 'axios'
import { apiConfig } from '../config'

const instance = axios.create()
instance.interceptors.response.use(
  (response) => response,
  (error) =>
    Promise.reject(
      (error.response && error.response.data) || 'Something went wrong'
    )
)

export default instance

const axiosInstance = axios.create({
  baseURL: apiConfig.apiUrl,
  withCredentials: false,
  headers: {
    'Access-Control-Allow-Origin': '*'
  }
})

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (
      error.response &&
      // error.response.config.url === '/api/app/new-provider/login-info' &&
      error.response.status === 401
    ) {
      // Redirect to login page
      window.location.href = '/#/portal-login'
    }
    return Promise.reject(
      (error.response && error.response.data) || 'Something went wrong'
    )
  }
)

export const instanceApi = axiosInstance
