import { ITutorial } from 'src/types/tutorial';
import { instanceApi } from 'src/utils/axios';
import { CRUDService } from './core/crudService';

export class TutorialService extends CRUDService<ITutorial> {

  protected apiPath = '/api/app/tutorial';

  updateStatus(id: string, status: 'publish' | 'unpublish') {
    return instanceApi.post(`${this.apiPath}/${id}/${status}`, {})
  }

  search(request: any) {
    return instanceApi.post(`${this.apiPath}/fitered-list`, {
      params: request,
    })
  }

}