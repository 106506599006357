/* eslint-disable no-unused-vars */

import { instanceApi } from 'src/utils'
import { Service } from './core/service'
import { IFamilySearchResponse } from 'src/types/families'

export class SearchService extends Service {
  protected apiPath = 'api/app/search'

  searchFamilyByName(searchTerm: string) {
    return instanceApi.post<IFamilySearchResponse[]>(
      `${this.apiPath}/families?keyword=${searchTerm}`
    )
  }

  globalSearch(searchTerm: string) {
    return instanceApi.get<IFamilySearchResponse[]>(
      `${this.apiPath}?keyword=${searchTerm}`
    )
  }
}
