/* eslint-disable no-unused-vars */

import { IOnboardingStatus } from 'src/types/onboarding'
import { instanceApi } from 'src/utils'
import { CRUDService } from './core/crudService'

export class OnboardingStatusService extends CRUDService<IOnboardingStatus> {
  protected apiPath = '/api/app/onboarding-status'

  protected fillable = [
    'providerId',
    'chooseSubscription',
    'basicInformation',
    'contact',
    'schedule',
    'programs',
    'tourSettings',
    'tourSchedule',
    'newPrograms',
    'sections',
    'classes',
    'micrositeSettings',
    'schoolOverview',
    'features',
    'overview',
    'location',
    'isSkipped',
    'isCompleted',
    'isCenterFound',
    'isAggrementsAccepted',
    'micrositeName',
    'micrositeLogo',
    'micrositePhotos',
    'micrositeAgeRange',
    'micrositeHours',
    'micrositeCalendar',
    'micrositeSchedules',
    'micrositeDescription',
    'micrositeSocialMedia',
    'micrositeChat',
    'micrositeSlug'
  ]

  getStatusByProvider() {
    return instanceApi.get<IOnboardingStatus>(`${this.apiPath}/for-provider`)
  }
}
