/* eslint-disable no-unused-vars */
import {
  ITemplateContent,
  ITemplateContentInput
} from 'src/types/email-template'
import { CRUDService } from './core/crudService'
import { instanceApi } from 'src/utils'

export class ProviderTemplateContentService extends CRUDService<ITemplateContent> {
  protected apiPath = '/api/app/provider-template-content'

  getTemplateContentByVariable(request: ITemplateContentInput) {
    return instanceApi.post(`${this.apiPath}/as-variable`, request)
  }

  getTemplateContentByType() {
    return instanceApi.get(`${this.apiPath}/as-variable?type=1`)
  }
}
