export const titleCase = (name = '') => {

  return name
    .toLocaleLowerCase()
    .replace(/[&_/\\#,+()$~%.'":*?<>{}]/g, ' ')
    .split(' ')
    .map((v) => v.charAt(0).toUpperCase() + v.slice(1))
    .join(' ');
};

