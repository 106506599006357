import { IBaseEntity, IPaginationRequest } from "./core";

export interface ITutorial extends IBaseEntity {
  title: string;
  subTitle: string;
  description: string;
  imageURL: string;
  videoURL: string;
  streamVideoURL: string;
  duration: number;
  isPublished: boolean;
  videoType: VideoTypeEnum;
  info: string;
  userId: string;
}

export enum VideoTypeEnum {
  VIDEO = 0,
  PICTURE = 1,
  TOUR = 2,
}

export enum TutorialStatusEnum {
  UNPUBLISHED = 'unpublished',
  PUBLISHED = 'published',
}


export interface IGetTutorialRequest extends IPaginationRequest {
  Sorting?: string;
}


export interface IGetFilteredTutorialRequest extends IPaginationRequest {
  Sorting?: string;
  filter?: string;
}
