/* eslint-disable no-unused-vars */
import { find } from 'lodash'
import { IPagination } from 'src/types'
import {
  IBaseFamily,
  IFamily,
  IFamilyPagination,
  IFamilyPaginationRequest,
  IGetByFilterInput,
  ILeadsFamily,
  IRegisteredFamily
} from 'src/types/families'
import { instanceApi } from 'src/utils/axios'
import { ChildService } from './childService'
import { CRUDService } from './core/crudService'
import { ParentService } from './parentService'

const childService = ChildService.getInstance<ChildService>()
const parentService = ParentService.getInstance<ParentService>()
export class FamilyService extends CRUDService<IFamily> {
  protected apiPath = '/api/app/family'

  protected fillable = ['name', 'providerId']

  getByFilters(request: IGetByFilterInput) {
    return instanceApi
      .post<IPagination<IFamily>>(`${this.apiPath}/by-filters`, request)
      .then((resp) => {
        resp.data.items = resp.data.items.map(this.mapItem)
        return resp
      })
  }

  getAllFamilies(request: IFamilyPaginationRequest) {
    return instanceApi.get<IFamilyPagination<IBaseFamily>>(
      `${this.apiPath}/families`,
      {
        params: request
      }
    )
  }

  getLeadFamilies(request: IFamilyPaginationRequest) {
    return instanceApi.get<IFamilyPagination<ILeadsFamily>>(
      `${this.apiPath}/leads-families`,
      {
        params: request
      }
    )
  }

  getNewFamilies(request: IFamilyPaginationRequest) {
    return instanceApi.get<IFamilyPagination<ILeadsFamily>>(
      `${this.apiPath}/enrollment-families`,
      {
        params: request
      }
    )
  }

  getActiveFamilies(request: IFamilyPaginationRequest) {
    return instanceApi.get<IFamilyPagination<ILeadsFamily>>(
      `${this.apiPath}/active-families`,
      {
        params: request
      }
    )
  }

  getRegisteredFamilies(request: IFamilyPaginationRequest) {
    return instanceApi.get<IFamilyPagination<IRegisteredFamily>>(
      `${this.apiPath}/registered-families`,
      {
        params: request
      }
    )
  }

  mapItem(item: IFamily) {
    if (item.parents) {
      item.parents = item.parents.map((child) => parentService.mapItem(child))
      if (!item.primaryParent) {
        item.primaryParent = find(item.parents, { isPrimaryContact: true })
      }
    }

    if (item.children) {
      item.children = item.children.map((child) => childService.mapItem(child))
    }
    return item
  }
}
