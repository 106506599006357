import { instanceApi } from 'src/utils/axios'
import { Service } from './core/service'

export class NewProviderService extends Service {
  protected apiPath = '/api/app/new-provider'

  loginSucceeded() {
    return instanceApi.post(`${this.apiPath}/login-succeed`)
  }

  logoutSucceeded() {
    return instanceApi.post(`${this.apiPath}/logout-succeed`)
  }

  loginInfo() {
    return instanceApi.get(`${this.apiPath}/login-info`)
  }
}
