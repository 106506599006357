// eslint-disable-next-line no-unused-vars
import { IAutomation } from 'src/types/automation'
import { instanceApi } from 'src/utils/axios'
import { Service } from './core/service'

export class AutomationService extends Service {
  protected apiPath = '/api/app/automation'

  getByAlias(alias: string) {
    return instanceApi.get<IAutomation>(
      `${this.apiPath}/by-alias?alias=${alias}`
    )
  }

  getAll() {
    return instanceApi.get<IAutomation>(
      `${this.apiPath}`
    )
  }

  update(request: any) {
    return instanceApi.post<IAutomation>(`${this.apiPath}`, request);
  }

  updateSpecific(id: string, request: any) {
    return instanceApi.put<IAutomation>(`${this.apiPath}/${id}`, request);
  }
}
