/* eslint-disable no-unused-vars */
import { IUpdateUserPreferencesNotification, IUserPreferencesNotification } from 'src/types/userPreferences'
import { Service } from './core/service'
import { instanceApi } from 'src/utils/axios'


export class UserPreferencesNotification extends Service {
  protected apiPath = '/api/app/user-preferences'

  getUserPreferencesNotification() {
    return instanceApi.get<IUserPreferencesNotification>(
      `${this.apiPath}/notification-user-preferences`
    )
  }

  updateUserPreferencesNotification(request: IUpdateUserPreferencesNotification[]) {
    return instanceApi.post<IUpdateUserPreferencesNotification>(`${this.apiPath}/create-update-notification-user-preferences`, request)
  }
}
