import { instanceApi } from 'src/utils/axios'
import { CRUDService } from './core/crudService'

export class GoogleCalendarService extends CRUDService<any> {
  protected apiPath = '/api/app/google-calendar'

  getAuthUrl() {
    return instanceApi.get(`${this.apiPath}/authorization-url`)
  }

  revokeAccess() {
    return instanceApi.post(`${this.apiPath}/revoke-access`)
  }

  getCalendarEvents(calendarId: string = 'primary') {
    return instanceApi.get(`${this.apiPath}/events/${calendarId}`)
  }
}
