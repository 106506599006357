/* eslint-disable no-unused-vars */
import {
  ICalendarEventSchedule,
  ICheckAvailabilityInput,
  ITourOccurenceInput,
  ITourSeriesInput
} from 'src/types/calendarEventSchedule'
import { instanceApi } from 'src/utils/axios'
import { CRUDService } from './core/crudService'

export class CalendarEventScheduleService extends CRUDService<ICalendarEventSchedule> {
  protected apiPath = '/api/app/calendar-event-schedule'

  createTourSeries(request: ITourSeriesInput) {
    return instanceApi.post(`${this.apiPath}/calendar-event-series`, request)
  }

  updateTourSeries(id: string, request: ITourSeriesInput) {
    return instanceApi.put(
      `${this.apiPath}/${id}/calendar-event-series`,
      request
    )
  }

  deleteTourSeriesById(calendarEventId: string) {
    return instanceApi.delete(
      `${this.apiPath}/all-calendar-event-series/${calendarEventId}`
    )
  }

  checkAvailability(request: ICheckAvailabilityInput) {
    return instanceApi.post(`${this.apiPath}/get-availability`, request)
  }

  deleteTourOccurence(request: ITourOccurenceInput) {
    return instanceApi.post(`${this.apiPath}/remove-series-instance`, request)
  }
}
