import { IBaseEntity } from './core'

export type media = 'media'

export interface IEnrollmentMedia extends IBaseEntity {
  [x: string]: any;
  id?: string;
  title?: string;
  description?: string;
  imageUrl?: string;
  textBlockLeft?: boolean;
  orderNumber?: number;
  mediaType?: MediaTypes;
  leftBlockSize?: string;
  type?: media;
  templateData?: any;
  enrollmentMediaColumnsInput?: Array<IEnrollmentMediaColumn>;
  enrollmentMediaColumns?: Array<IEnrollmentMediaColumn>;
  isDeleted?: boolean;
  providerId?: string;
  enrollmentProgramId?: string;
  enrollmentMediaGroupId?: string;
}

export enum EnrollmentMediaColumnTypeEnum {
  IMAGE = 'image',
  TEXT = 'text',
  TAGLINE = 'tagline',
  QUOTE = 'quote',
}

export interface ITextColumn {
  title?: string,
  description?: string;
  width?: number;
  type?: EnrollmentMediaColumnTypeEnum,
  keywords?: string; // To-do : Remove when title field add in api
}

export interface IImageColumn {
  imageUrl?: string,
  width?: number;
  type?: EnrollmentMediaColumnTypeEnum,
}

export interface ITaglineColumn {
  width?: number;
  description?: string,
  keywords?: string,
  type?: EnrollmentMediaColumnTypeEnum,
}

export interface IQuoteColumn {
  width?: number;
  description?: string,
  author?: string,
  type?: EnrollmentMediaColumnTypeEnum,
  keywords?: string; // To-do : Remove when author field add in api
}

export type IEnrollmentMediaColumn = ITextColumn | IImageColumn | ITaglineColumn;

export interface IEnrollmentMediaTemplate extends IBaseEntity {
  subscription?: string;
  templateType?: EnrollmentMediaGroupTypeEnum;
  templateStatus?: string;
  order?: number;
  htmlContent?: string;
  enrollmentMedia?: Array<{
    orderNumber?: number;
    mediaType?: MediaTypes;
    enrollmentMediaColumns?: Array<IEnrollmentMediaColumn>;
  }>;
}



export interface IEnrollmentMediaStyle {
  card?: EnrollmentMediaStylesEnum;
  align?: EnrollmentMediaAlignEnum;
  number?: EnrollmentMediaNumberStylesEnum;
}

export enum EnrollmentMediaAlignEnum {
  LEFT = 'left',
  CENTER = 'center',
  RIGHT = 'right',
}

export enum EnrollmentMediaStylesEnum {
  BASIC = 'basic',
  MODERN = 'modern',
}

export enum EnrollmentMediaNumberStylesEnum {
  PAINT = 'paint',
  ANIMAL_FINGERPRINT = 'animal-fingerprint',
  AQUARELLE = 'aquarelle',
  BALLOON = 'balloon',
  HAND = 'hand',
  LEGO = 'lego',
}

export enum EnrollmentMediaTypeEnum {
  CUSTOM = 'custom',
  TEMPLATE = 'template',
}

export enum MediaTypes {
  TAGLINE = 0,
  QUOTE = 1,
  HORIZONTAL_IMAGES = 2,
  HORIZONTAL_TEXT = 3,
  HORIZONTAL_TEXT_IMAGES = 4,

  // DO-TO : Will remove below,
  // Custom
  IMAGE = 0,
  TEXT = 1,
  TEXT_IMAGE = 2,
  // Templates
  YEAR_OLD_2 = 3,
  YEAR_OLD_3 = 4,
  YEAR_OLD_4 = 5,
  TODDLER = 6,
  INFANTE = 7,
  CUSTOM = 8,
}

export interface IEnrollmentMediaGroup extends IBaseEntity {
  type?: EnrollmentMediaGroupTypeEnum;
  name?: string;
  imageUrl?: string;
  order?: number;
  isDeleted?: boolean;
  providerId?: string;
  enrollmentProgramId?: string;
  enrollmentMedia?: IEnrollmentMedia[];
  enrollmentMediaInput?: IEnrollmentMedia[];
}

export enum EnrollmentMediaGroupTypeEnum {
  QUOTE = 3,
  TAGLINE = 2,
  CONTENT = 1,
  TABS = 0,
}