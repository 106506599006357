import { IProviderUserNoteRelation } from 'src/types/providerUserNote'
import { CRUDService } from './core/crudService'
import { instanceApi } from 'src/utils'

export class ProviderUserNoteRelationService extends CRUDService<IProviderUserNoteRelation> {
  protected apiPath = '/api/app/provider-user-note-relation'

  getAllLeadNotes(id: string) {
    return instanceApi.get<IProviderUserNoteRelation[]>(
      `${this.apiPath}/by-relation-id?DealId=${id}`
    )
  }

  getChildLeadNotes(id: string) {
    return instanceApi.get<IProviderUserNoteRelation[]>(
      `${this.apiPath}/by-relation-id?ChildId=${id}`
    )
  }

  getParentLeadNotes(id: string) {
    return instanceApi.get<IProviderUserNoteRelation[]>(
      `${this.apiPath}/by-relation-id?ParentId=${id}`
    )
  }

  setLeadNoteRelation(request: any) {
    return instanceApi.post<IProviderUserNoteRelation[]>(
      `${this.apiPath}`,
      request
    )
  }

  setLeadNoteMultipleRelation(request: any) {
    return instanceApi.post<IProviderUserNoteRelation[]>(
      `${this.apiPath}/multiple`,
      request
    )
  }
}
