export const currencyFormat = (amount: number, symbol = '$', decPlaces: number = 2, decSep: string = '.', thouSep: string = ',') => {
  return `${symbol}${formatMoney(amount, decPlaces, decSep, thouSep)}`;
};

function formatMoney(number: any, decPlaces: number, decSep: string, thouSep: string) {
  let sign = number < 0 ? "-" : "";
  let i = String(parseInt(number = Math.abs(Number(number) || 0).toFixed(decPlaces)));
  let j = i.length > 3 ? i.length % 3 : 0;

  return sign +
    (j ? i.substr(0, j) + thouSep : "") +
    i.substr(j).replace(/(\decSep{3})(?=\decSep)/g, "$1" + thouSep) +
    (decPlaces ? decSep + Math.abs(number - parseFloat(i)).toFixed(decPlaces).slice(2) : "");
}

