/* eslint-disable no-unused-vars */
import { IProviderSettings } from 'src/types/providerSettings'
import { CRUDService } from './core/crudService'
import { instanceApi } from 'src/utils'
export class ProviderSettingsService extends CRUDService<IProviderSettings> {
  protected apiPath = 'api/app/provider-settings'
  protected fillable = []

  getByProviderId(providerId: string) {
    return instanceApi.get<IProviderSettings>(
      `${this.apiPath}/by-provider-id/${providerId}`
    )
  }

  getProviderInboxSetting() {
    return instanceApi.get<any>(`${this.apiPath}/provider-inbox-settings`)
  }

  updateProviderInboxSetting(request: any) {
    return instanceApi.put(`${this.apiPath}/provider-inbox-settings`, request)
  }
}
