import { IEnrollmentAge } from 'src/types/program';
import { instanceApi } from 'src/utils/axios';
import { CRUDService } from './core/crudService'

export class EnrollmentAgeService extends CRUDService<IEnrollmentAge> {
  protected apiPath = '/api/app/enrollment-age'

  createOrUpdate(request: any, id?: any) {
    if (id) {
      return super.update(id, request);
    } else {
      return super.create(request);
    }
  }

  multipleCreateUpdate(request: any) {
    return instanceApi.post<IEnrollmentAge[]>(`${this.apiPath}/multiple-create-update`, request)
  }

}
