import {
  IConversationMessage,
  IConversationMessageByParentInput
} from 'src/types/conversation'
import { IPagination, IPaginationRequest } from 'src/types/core'
import { instanceApi } from 'src/utils/axios'
import { CRUDService } from './core/crudService'

export class ConversationMessageService extends CRUDService<IConversationMessage> {
  protected apiPath = '/api/app/conversation-message'

  byParent(request: IConversationMessageByParentInput) {
    return instanceApi.post<IConversationMessage>(
      `${this.apiPath}/by-parent`,
      request
    )
  }

  byThreadId(threadId, request: IPaginationRequest = {}) {
    const defaultRequest = {
      Sorting: 'creationTime ASC',
      MaxResultCount: 30
    }

    return instanceApi.get<IPagination<IConversationMessage>>(
      `${this.apiPath}/conversation/${threadId}`,
      { params: { ...defaultRequest, ...request } }
    )
  }
}
