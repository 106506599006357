/* eslint-disable no-unused-vars */
import { omit, pick } from 'lodash'
import {
  IProgram,
  IUpdateMultipleClassesInput,
  IUpdateMultipleSessionsInput
} from 'src/types/program'
import { instanceApi } from 'src/utils'
import { CRUDService } from './core/crudService'
import { EnrollmentSessionService } from './enrollmentSessionService'
import { EnrollmentClassService } from './enrollmentClassService'
import { EnrollmentMediaService } from './enrollmentMediaService'
import { EnrollmentTimelineService } from './enrollmentTimelineService'
import {
  IEnrollmentMedia,
  IEnrollmentSession,
  session,
  IEnrollmentClass,
  EnrollmentMediaStylesEnum,
  EnrollmentMediaAlignEnum,
  EnrollmentMediaNumberStylesEnum
} from 'src/types'
import { EnrollmentMediaGroupService } from './enrollmentMediaGroupService'

const enrollmentClassService = EnrollmentClassService.getInstance<EnrollmentClassService>()
const enrollmentSessionService = EnrollmentSessionService.getInstance<EnrollmentSessionService>()
const enrollmentMediaService = EnrollmentMediaService.getInstance<EnrollmentMediaService>()
const enrollmentMediaGroupService = EnrollmentMediaGroupService.getInstance<EnrollmentMediaGroupService>()
const enrollmentTimelineService = EnrollmentTimelineService.getInstance<EnrollmentTimelineService>()

export class ProgramService extends CRUDService<IProgram> {
  protected apiPath = 'api/app/enrollment-program'

  protected fillable = [
    'id',
    'creationTime',
    'creatorId',
    'lastModificationTime',
    'lastModifierId',
    'providerId',
    'name',
    'description',
    'isPublished',
    'billing',
    'status',
    'startDate',
    'endDate',
    'enrollmentStart',
    'enrollmentStatus',
    'enrollmentSessions',
    'enrollmentMediaStyle',
    'enrollmentMedia',
    'enrollmentMediaGroups',
    'micrositeTimelines',
    'micrositeImageUrl',
    'isDeleted',
    'emailAddress',
    'orderNumber'
  ]

  updateMultiple(programs: IProgram[]) {
    const request = programs.map((program) => this.requestMap(program))
    return instanceApi
      .post<IProgram[]>(`${this.apiPath}/multiple-enrollment-programs`, request)
      .then((resp) => {
        resp.data = resp.data.map((program) => this.mapItem(program))
        return resp
      })
  }

  updateMultipleSessions(request: IUpdateMultipleSessionsInput) {
    return instanceApi.post<IEnrollmentSession[]>(
      `${this.apiPath}/multiple-enrollment-sessions`,
      request
    )
  }

  updateMultipleClasses(request: IUpdateMultipleClassesInput) {
    return instanceApi.post<IEnrollmentClass[]>(
      `${this.apiPath}/multiple-enrollment-classes`,
      request
    )
  }

  mapItem(row: IProgram) {
    if (row.enrollmentClasses) {
      row.enrollmentClasses = row.enrollmentClasses.map(
        enrollmentClassService.mapItem
      )
    }
    if (row.enrollmentMedia) {
      row.enrollmentMedia = row.enrollmentMedia.map(
        enrollmentMediaService.mapItem
      )
    }

    if (row.enrollmentMediaGroups) {
      row.enrollmentMediaGroups = row.enrollmentMediaGroups.map(
        enrollmentMediaGroupService.mapItem
      )
    }

    if (row.micrositeTimelines) {
      row.micrositeTimelines = row.micrositeTimelines.map(
        enrollmentTimelineService.mapItem
      )
    }

    try {
      if (typeof row.enrollmentMediaStyle === 'string') {
        row.enrollmentMediaStyle = JSON.parse(row.enrollmentMediaStyle)
      }
    } catch (error) {
      row.enrollmentMediaStyle = null
    }

    if (!row.enrollmentMediaStyle) {
      row.enrollmentMediaStyle = {
        card: EnrollmentMediaStylesEnum.BASIC,
        align: EnrollmentMediaAlignEnum.LEFT,
        number: EnrollmentMediaNumberStylesEnum.PAINT
      }
    }

    if (row.enrollmentSessions) {
      row.enrollmentSessions = row.enrollmentSessions.map(
        (enrollmentSession) => ({
          ...enrollmentSession,
          enrollmentClasses: enrollmentSession.enrollmentClasses.map(
            enrollmentClassService.mapItem
          )
        })
      )
    }
    if (row.enrollmentSessions || row.enrollmentMedia) {
      row.enrollmentMediaAndSessions = [
        ...((row.enrollmentSessions &&
          row.enrollmentSessions.map((enrollmentSession) => ({
            ...enrollmentSession,
            enrollmentClasses: enrollmentSession.enrollmentClasses.map(
              enrollmentClassService.mapItem
            ),
            type: 'session' as session
          }))) ||
          []),
        ...((row.enrollmentMedia &&
          row.enrollmentMedia.map(enrollmentMediaService.requestMap)) ||
          [])
      ].sort((a, b) => {
        if (a.orderNumber > b.orderNumber) return 1
        if (a.orderNumber < b.orderNumber) return -1
        return 0
      })
    }
    return row
  }

  private requestMap(program: IProgram) {
    const updatedProgram = { ...program }

    if (updatedProgram.isPublished == null) {
      updatedProgram.isPublished = false
    }

    if (updatedProgram.enrollmentMediaStyle) {
      updatedProgram.enrollmentMediaStyle = JSON.stringify(
        updatedProgram.enrollmentMediaStyle
      ) as any
    }

    const enrollmentMediaAndSessions: (
      | IEnrollmentSession
      | IEnrollmentMedia
    )[] = updatedProgram.enrollmentMediaAndSessions.map(
      (enrollmentMediaAndSession, index) => {
        const data =
          enrollmentMediaAndSession.type === 'session'
            ? updatedProgram.enrollmentSessions.find(
                (enrollmentSession) =>
                  enrollmentSession.id === enrollmentMediaAndSession.id
              )
            : updatedProgram.enrollmentMedia.find(
                (enrollmentMedia) =>
                  enrollmentMedia.id === enrollmentMediaAndSession.id
              )
        return {
          ...enrollmentMediaAndSession,
          ...data,
          orderNumber: index
        }
      }
    )

    let enrollmentSessions = null
    enrollmentSessions = enrollmentMediaAndSessions
      .filter(
        (enrollmentMediaAndSession) =>
          enrollmentMediaAndSession.type === 'session'
      )
      .map((enrollmentMediaAndSession) =>
        enrollmentSessionService.convertToRequestFormat(
          enrollmentMediaAndSession as IEnrollmentSession
        )
      )

    let enrollmentMediaGroups = []
    if (updatedProgram.enrollmentMediaGroups) {
      enrollmentMediaGroups = updatedProgram.enrollmentMediaGroups.map(
        (item) => {
          const updatedItem = enrollmentMediaGroupService.requestMap(item)
          if (!updatedItem.creationTime) {
            return omit(updatedItem, 'id')
          } else {
            return updatedItem
          }
        }
      )
    }

    // const enrollmentMedia = chain(enrollmentMediaGroups)
    //   .map('enrollmentMedia')
    //   .flatten()
    //   .map((item, index) => ({ ...item, orderNumber: index }))
    //   .value();

    const enrollmentMedia = []

    return {
      ...pick(updatedProgram, this.fillable),
      enrollmentSessions,
      enrollmentMedia,
      enrollmentMediaGroups
    }
  }
}
