import { cloneDeep } from 'lodash';
import { IChatBotQuestionGroup } from 'src/types/chat';
import { instanceApi } from 'src/utils/axios';
import { CRUDService } from './core/crudService';

export class ProviderChatQuestionGroupService extends CRUDService<IChatBotQuestionGroup> {

    protected apiPath = '/api/app/provider-chat-question-group';

    createMultiple(request?: IChatBotQuestionGroup[]) {
        request = request.map(this.requestMap);
        return instanceApi
            .post<IChatBotQuestionGroup[]>(`${this.apiPath}/multiple-provider-chat-question-groups`, request)
    }


    create(request: Partial<IChatBotQuestionGroup> = {}) {
        request = this.requestMap(request)
        return super.create(request)
    }

    update(id: string | number, request: Partial<IChatBotQuestionGroup> = {}) {
        request = this.requestMap(request)
        return super.update(id, request)
    }


    requestMap(data: IChatBotQuestionGroup) {
        const updatedData = cloneDeep(data);

        if (updatedData.providerChatQuestions && !updatedData.providerChatQuestionsInput) {
            updatedData.providerChatQuestionsInput = updatedData.providerChatQuestions;
            delete updatedData.providerChatQuestions;
        }

        return updatedData
    }

}
