/* eslint-disable no-unused-vars */
import { ILogoCollection } from 'src/types/logo'
import { instanceApi } from 'src/utils/axios'
import { CRUDService } from './core/crudService'

export class LogoCollectionService extends CRUDService<ILogoCollection> {
  protected apiPath = '/api/app/provider/logo-collection'

  getLogoCollection() {
    return instanceApi.get<ILogoCollection[]>(`${this.apiPath}`)
  }
}
