import { instanceApi } from 'src/utils/axios'
import { IProviderUserNote } from 'src/types/providerUserNote'
import { CRUDService } from './core/crudService'

export class ProviderUserNoteService extends CRUDService<IProviderUserNote> {
  protected apiPath = '/api/app/provider-user-note'

  get() {
    return instanceApi.get<any>(`${this.apiPath}`)
  }

  putById(id: string, request: any) {
    return instanceApi.put<any>(`${this.apiPath}/${id}`, request)
  }

  deleteById(id: string) {
    return instanceApi.delete<any>(`${this.apiPath}/${id}`)
  }

  post(request: any) {
    return instanceApi.post<any>(`${this.apiPath}`, request)
  }
}
