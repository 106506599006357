/* eslint-disable no-unused-vars */
import { CRUDService } from './core/crudService'
import { instanceApi } from 'src/utils/axios'

export class SessionService extends CRUDService<any> {
  protected apiPath = 'api/app/session'

  extendSession() {
    return instanceApi.post<any>(`${this.apiPath}/extend-session`)
  }
}
