import { IChangePasswordInput, IProfile } from 'src/types/user'
import { instanceApi } from 'src/utils/axios'
import { Service } from './core/service'

export class ProfileService extends Service {
  protected apiIdentityPath = '/api/identity/my-profile'
  protected apiAppPath = '/api/app/profile'

  get() {
    return instanceApi.get<IProfile>(`${this.apiIdentityPath}`)
  }

  update(request: Partial<IProfile> = {}) {
    return instanceApi.put<IProfile>(`${this.apiIdentityPath}`, request)
  }

  changePassword(request: IChangePasswordInput) {
    return instanceApi.post(`${this.apiIdentityPath}/change-password`, request)
  }

  getOVProfile() {
    return instanceApi.get<IProfile>(`${this.apiAppPath}`)
  }

  updateOVProfile(request: Partial<IProfile> = {}) {
    return instanceApi.put<IProfile>(`${this.apiAppPath}`, request)
  }

  changeOVPassword(request: IChangePasswordInput) {
    return instanceApi.post(`${this.apiAppPath}/change-password`, request)
  }
}
