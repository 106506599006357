/* eslint-disable no-unused-vars */
import { ICalendarEvent } from 'src/types/calendar'
import { instanceApi } from 'src/utils/axios'
import { CRUDService } from './core/crudService'

export class NewCalendarEventService extends CRUDService<ICalendarEvent> {
  protected apiPath = '/api/app/new-calendar-event'

  protected fillable = [
    'providerId',
    'provider',
    'title',
    'description',
    'allDay',
    'frequency',
    'startDate',
    'endDate',
    'startTime',
    'endTime',
    'meetingLocation',
    'zoomMeetingId',
    'nullable',
    'parents',
    'childs',
    'duration',
    'daysInTheFuture',
    'meetingType',
    'bufferBeforeMeeting',
    'bufferAfterMeeting',
    'mondayHours',
    'tuesdayHours',
    'wednesdayHours',
    'thursdayHours',
    'fridayHours',
    'saturdayHours',
    'sundayHours',
    'overlapDates'
  ]

  getEventByType(meetingType: string, startDate?: string, endDate?: string) {
    return instanceApi.get<ICalendarEvent>(`${this.apiPath}/meeting-type`, {
      params: { meetingType, startDate, endDate }
    })
  }

  changeLatestTimePriorBooking(
    meetingType: string,
    latestTimePriorBeforeBooking: number
  ) {
    return instanceApi.post<any>(`${this.apiPath}/`, {
      meetingType,
      latestTimePriorBeforeBooking
    })
  }
}
