export function isEmpty(value): boolean {

  if (typeof value === 'string') {
    return !value.trim();
  }
  if (typeof value === 'boolean') {
    return false;
  }

  if (!value) {
    return true;
  }

  if (typeof value === 'object') {
    if (value.length) {
      return value.length === 0;
    } else {
      return Object.values(value).length === 0;
    }
  }

  return false;
};

