/* eslint-disable no-unused-vars */
import { IBaseEntity } from './core'

export interface Contact {
  id: string
  avatar: string
  isActive: boolean
  lastActivity: number
  name: string
  username: string
}

interface ThreadMessageAttachment {
  id: string
  url: string
}

export interface ThreadMessage {
  id: string
  attachments: ThreadMessageAttachment[]
  body: string
  contentType: string
  createdAt: number
  senderId: string
}

export interface ThreadParticipant {
  id: string
  avatar: string | null
  name: string
  username: string
}

export interface Thread {
  id?: string
  messages: ThreadMessage[]
  participants: ThreadParticipant[]
  type: 'ONE_TO_ONE' | 'GROUP'
  unreadCount: number
}

export interface Recipient {
  id: string
  avatar: string | null
  name: string
}

export interface IChatBotQuestion extends IBaseEntity {
  providerId?: string
  providerChatQuestionGroupId?: string
  anonymousUserId?: string
  topic?: string
  question?: string
  answer?: string
  isActive?: boolean
  orderNumber?: number
  isDeleted?: boolean
}

export interface IChatBotQuestionGroup extends IBaseEntity {
  name?: string
  providerId?: string
  orderNumber?: number
  providerChatBotSettingId?: string
  isDeleted?: boolean
  providerChatQuestions?: IChatBotQuestion[]
  providerChatQuestionsInput?: IChatBotQuestion[]
}

export interface IContactPerson {
  firstName?: string
  lastName?: string
  imageUrl?: string
}

export interface IChatBotSettings extends IBaseEntity {
  providerId?: string
  profileType?: ChatBotProfileEnum
  greeting?: string
  greetingIsActive?: boolean
  autoMessage?: string
  autoMessageIsActive?: boolean
  providerChatQuestionGroups?: IChatBotQuestionGroup[]
  providerChatQuestionGroupsInput?: IChatBotQuestionGroup[]
  contactPerson?: IContactPerson
  userProviderId?: string
}

export enum ChatBotProfileEnum {
  CENTER = 0,
  USER = 1
}
