export function stripHtmlTags(html: string, bbcodePluginLoaded?: boolean) {
  if (bbcodePluginLoaded) {
    return html.replace(/\[.*?\]/gi, "");
  }

  const tmp = document.createElement("div");
  tmp.innerHTML = html;

  if (tmp.textContent == "" && typeof tmp.innerText == "undefined") {
    return "";
  }
  return tmp.textContent || tmp.innerText;
}


export function countCharacters(text: string, countSpacesAsChars?: boolean, countLineBreaks?: boolean) {

  let normalizedText = text || '';

  if (!countSpacesAsChars) {
    normalizedText = text?.replace(/\s/g, "").replace(/&nbsp;/g, "");
  }

  if (countLineBreaks) {
    normalizedText = normalizedText?.replace(/(\r\n|\n|\r)/gm, " ");
  } else {
    normalizedText = normalizedText?.replace(/(\r\n|\n|\r)/gm, "")?.replace(/&nbsp;/gi, " ");
  }

  normalizedText = stripHtmlTags(normalizedText)?.replace(/^([\t\r\n]*)$/, "");

  return normalizedText.length;
}
