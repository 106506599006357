// eslint-disable-next-line no-unused-vars
import { IConversationThread, IConversation } from 'src/types/conversation'
import { instanceApi } from 'src/utils/axios'
import { CRUDService } from './core/crudService'

export class ThreadService extends CRUDService<IConversationThread> {
  protected apiPath = '/api/app/thread'

  getThreads(threadType: number) {
    return instanceApi.get<IConversation>(
      `${this.apiPath}/by-provider?ThreadType=${threadType}`
    )
  }

  closeThread(threadId: string) {
    return instanceApi.post(`${this.apiPath}/close/${threadId}`)
  }

  reopenThread(threadId: string) {
    return instanceApi.post(`${this.apiPath}/re-open/${threadId}`)
  }

  markAsRead(id: string) {
    return instanceApi.put(`${this.apiPath}/${id}/thread-as-read`, {})
  }

  getActiveThreadByParent(parentId: string) {
    return instanceApi.get(
      `${this.apiPath}/latest-active-by-parent?ParentId=${parentId}&Type=1`
    )
  }

  getAllThreadsByParent(parentId: string) {
    return instanceApi.get(`${this.apiPath}/by-parent/${parentId}`)
  }
}
