/* eslint-disable no-unused-vars */
import { IMicrositeAccess } from 'src/types/micrositeAccess'
import { CRUDService } from './core/crudService'
import { instanceApi } from 'src/utils'

export class MicrositeAccessService extends CRUDService<IMicrositeAccess> {
  protected apiPath = '/api/app/microsite-access'

  protected fillable = [
    'id',
    'providerId',
    'site',
    'virtualTour',
    'slideshow',
    'programs',
    'location',
    'scheduleTour',
    'enroll',
    'chat',
    'enableParentPortalFlow',
    'showPrice'
  ]

  create(request: Partial<IMicrositeAccess> = {}) {
    request = this.requestMap(request)
    return instanceApi.post<IMicrositeAccess>(`${this.apiPath}`, request)
  }

  update(id: string | number, request: Partial<IMicrositeAccess> = {}) {
    return instanceApi.put<IMicrositeAccess>(`${this.apiPath}/` + id, request)
  }

  get() {
    return instanceApi.get<IMicrositeAccess>(`${this.apiPath}`)
  }

  getDetailsByProvider() {
    return instanceApi.get<IMicrositeAccess>(`${this.apiPath}/for-provider`)
  }

  mapItem(row: IMicrositeAccess) {
    return row
  }

  private requestMap(request) {
    request = Object.assign({}, request)
    if (request.site) {
      request.site = 'true'
    } else {
      request.site = 'false'
    }
    if (request.virtualTour) {
      request.virtualTour = 'true'
    } else {
      request.virtualTour = 'false'
    }
    if (request.slideshow) {
      request.slideshow = 'true'
    } else {
      request.slideshow = 'false'
    }
    if (request.programs) {
      request.programs = 'true'
    } else {
      request.programs = 'false'
    }
    if (request.location) {
      request.location = 'true'
    } else {
      request.location = 'false'
    }
    if (request.scheduleTour) {
      request.scheduleTour = 'true'
    } else {
      request.scheduleTour = 'false'
    }
    if (request.enroll) {
      request.enroll = 'true'
    } else {
      request.enroll = 'false'
    }
    if (request.chat) {
      request.chat = 'true'
    } else {
      request.chat = 'false'
    }
    if (request.enableParentPortalFlow) {
      request.enableParentPortalFlow = 'true'
    } else {
      request.enableParentPortalFlow = 'false'
    }
    if (request.showPrice) {
      request.showPrice = 'true'
    } else {
      request.showPrice = 'false'
    }
    return request
  }
}
