import { IPagination } from 'src/types/core'
import {
  IEncodeMedia,
  IGetMediaFilesById,
  IMediaFile,
  ISearchMediaFileInput
} from 'src/types/tours'
import { instanceApi } from 'src/utils/axios'
import { CRUDService } from './core/crudService'

export class MediaFileService extends CRUDService<IMediaFile> {
  static instance: any
  static mediaSasToken: any

  protected apiPath = 'api/app/media-file'

  static urlWithSasToken(url: string) {
    return url
  }

  static async init() {}

  getAllByAlbum(request: IGetMediaFilesById) {
    return instanceApi.get<IPagination<IMediaFile>>(
      `${this.apiPath}/by-album-id`,
      { params: request }
    )
  }

  getMediaSasToken() {
    return instanceApi.get<string>(`${this.apiPath}/media-sas-token`)
  }

  getSasToken() {
    return instanceApi.get<string>(`${this.apiPath}/sas-token`)
  }

  getUnsplashPhotos(request: any) {
    return instanceApi.get<any>(`${this.apiPath}/unsplash-collection-photos`, {
      params: request
    })
  }

  updateMediaFilesAlbum(albumId, request) {
    return instanceApi.put<string>(
      `${this.apiPath}/media-files-album`,
      request,
      { params: { albumId } }
    )
  }

  search(request: ISearchMediaFileInput = {}) {
    return instanceApi
      .get<IPagination<IMediaFile>>(`${this.apiPath}/search`, {
        params: request
      })
      .then((resp) => {
        resp.data.items = resp.data.items.map((file) => this.mapItem(file))
        return resp
      })
  }

  encodeMedia(params = {}) {
    return instanceApi.post<IEncodeMedia>(
      `${this.apiPath}/encode-media`,
      null,
      { params }
    )
  }

  mapItem(item) {
    item.thumbnail = item.isVideo ? item.thumbnail : item.path
    return item
  }
}
