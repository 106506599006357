import { IParent } from 'src/types/parent'
import { instanceApi } from 'src/utils/axios'
import { last } from 'lodash'
import { IConversation } from 'src/types'
import { IPagination } from 'src/types/core'
import { CRUDService } from './core/crudService'

export class ParentService extends CRUDService<IParent> {
  protected apiPath = '/api/app/parent'

  protected fillable = [
    'userId',
    'firstName',
    'lastName',
    'email',
    'phone',
    'facebook',
    'facebookUrl',
    'instagramUrl',
    'linkedinUrl',
    'twitterUrl',
    'familyId',
    'familyName',
    'addressId',
    'address',
    'avatar',
    'isPrimaryContact',
    'registrationStatus'
  ]

  getCurrentParent() {
    return instanceApi.get<string>(`${this.apiPath}/parent-id`)
  }

  withThreads() {
    return instanceApi
      .get<IPagination<IParent>>(`${this.apiPath}/with-threads`)
      .then((resp) => {
        resp.data.items = resp.data.items.map((file) => this.mapItem(file))
        return resp
      })
  }

  mapItem(item: IParent) {
    if ((item as IConversation).threads) {
      const conversation = item as IConversation
      conversation.lastMessage = last(conversation.threads)?.lastMessage
    }

    return item
  }
}
