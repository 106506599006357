import { IBaseEntity } from './core'

export interface ITask extends IBaseEntity {
  providerId?: string;
  parentId?: string;
  userId?: string;
  name?: string;
  status?: TaskStatusEnum;
  taskType?: TaskTypeEnum;
  dueDate?: string;
  priority?: TaskPriorityEnum;
  ownerRole?: string;
  association?: TaskAssociationEnum;
}

export interface ITaskByProviderResponse {
  items: ITask[];
  totalCount: number;
}

export enum TaskPriorityEnum {
  HIGH = 0,
  MEDIUM = 1,
  LOW = 2,
}

export enum TaskStatusEnum {
  Done = 0,
  NotDone = 1,
  Skipped = 2,
}

export enum TaskTypeEnum {
  AtAGlance = 0,
  Setup = 1,
}

export enum TaskAssociationEnum {
  PROFILE = 'profile',
  MICROSITE = 'microsite',
}