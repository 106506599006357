export const truncate = (text: string, limit = 200, moreStr = '...') => {
  return (text && text.length > limit) ? text.slice(0, limit) + moreStr : text;
};


export const shrinkString = (text: string, limit = 200, trailingCharCount) => {
  let shrinkStr = text;
  const shrinkLength = limit - trailingCharCount - 3;
  if (text.length > shrinkLength) {
    const front = text.substr(0, shrinkLength);
    const mid = '...';
    const end = text.substr(-trailingCharCount);
    shrinkStr = front + mid + end;
  }
  return shrinkStr;
}
