/* eslint-disable no-unused-vars */
import { map, pick, split } from 'lodash'
import {
  IAgeClassRequest,
  IEnrollmentAgeClass,
  IEnrollmentClass
} from 'src/types/enrollment-class'
import { CRUDService } from './core/crudService'
import { instanceApi } from 'src/utils/axios'

export class EnrollmentClassService extends CRUDService<IEnrollmentClass> {
  protected apiPath = 'api/app/enrollment-class'

  protected fillable = [
    'id',
    'title',
    'description',
    'providerId',
    'enrollmentProgramId',
    'enrollmentAgeIds',
    'enrollmentSessionId',
    'enrollmentProgramScheduleId',
    'enrollmentClassesOptionsInput',
    'micrositeImageUrl',

    'price',
    'priceType',
    'pricing',
    'capacity',
    'openings',
    'enrolled',
    'age',
    'location',
    'startHour',
    'endHour',
    'daysOfOperation',
    'orderNumber'
  ]

  create(request: Partial<IEnrollmentClass> = {}) {
    request = this.requestMap(request)
    return super.create(request)
  }

  getEnrollmentClassWithAge() {
    return instanceApi.get<IEnrollmentClass>(`${this.apiPath}/with-ages`)
  }

  update(id: string | number, request: Partial<IEnrollmentClass> = {}) {
    request = this.requestMap(request)
    return super.update(id, request)
  }

  getAgeClassList() {
    return instanceApi.get<IEnrollmentAgeClass[]>(
      `${this.apiPath}/age-class-list`
    )
  }

  updateAgeClass(request: IAgeClassRequest, id: string) {
    return instanceApi.put<IEnrollmentAgeClass>(
      `${this.apiPath}/${id}/age-class`,
      request
    )
  }

  mapItem = (row: IEnrollmentClass) => {
    if (row.daysOfOperation && typeof row.daysOfOperation === 'string') {
      const hasVaries = row.daysOfOperation.indexOf('(Varies)') !== -1
      if (!hasVaries) {
        const days = this.getDays(row.daysOfOperation)
        row.daysOfOperation = days
      }
    } else {
      row.daysOfOperation = row.daysOfOperation || []
    }

    if (row.enrollmentClassAges) {
      row.enrollmentAgeIds = map(
        row.enrollmentClassAges,
        (classAge) => classAge.enrollmentAge.id
      )
    }

    if (typeof row.micrositeImageUrl === 'string') {
      row.micrositeImageUrl = split(row.micrositeImageUrl, ',')
    }

    return row
  }

  private weekdays = [
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
    'Sunday'
  ]

  private getDays(value: string) {
    const days = value.split(',').reduce((acc, item) => {
      if (item.includes('-')) {
        const [firstItem, secondItem] = item.split('-')
        const firstItemIndex = this.weekdays.findIndex(
          (item) => item === firstItem.trimStart()
        )
        const secondItemIndex = this.weekdays.findIndex(
          (item) => item === secondItem.trimEnd()
        )
        acc.push(this.weekdays.slice(firstItemIndex, secondItemIndex + 1))
      } else {
        acc.push(item.trim())
      }
      return acc
    }, [])
    const flatDays = days.flat()
    const uniqueDays = flatDays.filter(
      (day, index) => flatDays.indexOf(day) === index
    )
    return uniqueDays
  }

  convertToRequestFormat(enrollmentClass: IEnrollmentClass) {
    return pick(this.requestMap(enrollmentClass), this.fillable)
  }

  private requestMap(request) {
    request = Object.assign({}, request)
    if (!(request.id && isNaN(request.id))) {
      delete request.id
    }
    if (request.daysOfOperation instanceof Array) {
      request.daysOfOperation = request.daysOfOperation.join(', ')
    }
    if (request.micrositeImageUrl instanceof Array) {
      request.micrositeImageUrl = request.micrositeImageUrl.join(',')
    }
    // if (request.enrollmentClassAges instanceof Array) {
    //   request.enrollmentAgeIds = request.enrollmentClassAges
    // }
    return request
  }
}
