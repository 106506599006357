
import { ISlideMediaFile } from 'src/types/slide';
import { IMediaFileWithText } from 'src/types/tours';
import { instanceApi } from 'src/utils/axios';
import { CRUDService } from './core/crudService';

export class SlideMediaFileService extends CRUDService<ISlideMediaFile> {
  protected apiPath = 'api/app/slide-media-file';

  async syncFiles(newFiles: IMediaFileWithText[], input: any = {}) {
    const request = {
      ...input,
      files: newFiles.map((file, index) => ({ mediaFileId: file.id, text: file.text || file.title, fileOrder: index }))
    }
    return instanceApi.post<ISlideMediaFile[]>(`${this.apiPath}/multiple-files`, request)
  }
}