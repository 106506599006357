import { CRUDService } from './core/crudService'
import { IEnrollmentMediaGroup } from 'src/types/enrollment-media'
import { EnrollmentMediaService } from './enrollmentMediaService';
import { instanceApi } from 'src/utils/axios';

const enrollmentMediaService = EnrollmentMediaService.getInstance<EnrollmentMediaService>();

export class EnrollmentMediaGroupService extends CRUDService<IEnrollmentMediaGroup> {
  protected apiPath = 'api/app/enrollment-media-group'

  protected fillable = [
    'type',
    'name',
    'imageUrl',
    'order',
    'isDeleted',
    'providerId',
    'enrollmentMedia',
    'enrollmentMediaInput',
  ]

  createMultiple(request: any = []) {
    return instanceApi
      .post<IEnrollmentMediaGroup[]>(`${this.apiPath}/multiple`, request);
  }

  mapItem(row: IEnrollmentMediaGroup) {
    if (row.enrollmentMedia) {
      row.enrollmentMedia = row.enrollmentMedia.map(enrollmentMediaService.mapItem);
    }
    return row;
  }

  requestMap(enrollmentMedia: IEnrollmentMediaGroup) {
    const updatedEnrollmentMedia = Object.assign({}, enrollmentMedia)

    if (updatedEnrollmentMedia.enrollmentMedia) {
      updatedEnrollmentMedia.enrollmentMedia = updatedEnrollmentMedia.enrollmentMedia.map(enrollmentMediaService.requestMap);
    }

    if (
      updatedEnrollmentMedia.enrollmentMedia && !updatedEnrollmentMedia.enrollmentMediaInput
    ) {
      updatedEnrollmentMedia.enrollmentMediaInput =
        updatedEnrollmentMedia.enrollmentMedia

      delete updatedEnrollmentMedia.enrollmentMedia;
    }
    return updatedEnrollmentMedia
  }
}
