/* eslint-disable no-unused-vars */
import { CRUDService } from './core/crudService'
import { instanceApi } from 'src/utils'
import { IEnrollmentTimeline } from 'src/types/program'

export class EnrollmentTimelineService extends CRUDService<IEnrollmentTimeline> {
  protected apiPath = '/api/app/microsite-timeline'
  protected fillable = [
    'description',
    'duration',
    'enrollmentProgramId',
    'id',
    'micrositeTimelinesSlideshows',
    'name',
    'order',
    'providerId'
  ]

  copyTimeline(request: Partial<IEnrollmentTimeline> = {}) {
    return instanceApi.post<IEnrollmentTimeline>(
      `${this.apiPath}/copy-timeline`,
      request
    )
  }
}
