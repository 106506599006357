import { instanceApi } from 'src/utils/axios'
import { Service } from './core/service'

export class EmailService extends Service {
  protected apiPath = '/api/app/email'

  getPostmarkEmailAddresses() {
    return instanceApi.get<any>(`${this.apiPath}/postmark-email-addresses`)
  }

  setPostmarkEmailAddresses(request: any = {}) {
    return instanceApi.put<any>(
      `${this.apiPath}/postmark-email-addresses`,
      request
    )
  }

  sendEmail(toAddresses: string[]) {
    const request = {
      templateAlias: 'new-website-button',
      emailsTo: toAddresses
    }
    return instanceApi.post<any>(`${this.apiPath}/send-email-template`, request)
  }
}
