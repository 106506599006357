import { IBaseEntity, IPaginationRequest } from './core';
import { IMediaFile } from './tours';

export interface ISlide extends IBaseEntity {
  [x: string]: any;
  title?: string;
  subTitle?: string;
  description?: string;
  imageURL?: string;
  isPublished?: boolean;
  slideMediaFiles?: ISlideMediaFile[];
}

export interface ISlideMediaFile extends IBaseEntity {
  slideId?: string;
  title?: string;
  description?: string;
  mediaFileId?: string;
  providerId?: string;
  fileOrder?: string;
  mediaFile?: IMediaFile;
}

export enum SlideStatusEnum {
  UNPUBLISHED = 'unpublished',
  PUBLISHED = 'published'
}

export interface IGetSlideRequest extends IPaginationRequest {
  Sorting?: string;
}
