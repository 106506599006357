import moment from "moment";

export function toDateTime(date: any, toLocalTime = false) {
  return (toLocalTime ? toLocal(date) : moment(date)).format('MM/DD/YYYY hh:mm a');
}


export function toTime(date: any) {
  return moment(date).format('hh:mm a');
}


export function toDate(date: any, format = 'MM/DD/YYYY') {
  return moment(date).format(format);
}


export function toLocal(date: any) {
  return moment.utc(date).local();
}


export function dobToAge(date: any, prefix: 'small' | 'full' = 'small') {
  const duration = moment.duration(moment().diff(moment(date)));
  const years = duration.years();
  const months = duration.months();
  const weeks = duration.weeks();

  const yearPrefix = prefix === 'small' ? 'Y' : ' Year';
  const monthPrefix = prefix === 'small' ? 'M' : ' Month';
  const weekPrefix = prefix === 'small' ? 'W' : ' Week';

  if (years > 0) {
    if (months > 0) {
      return `${years}${yearPrefix} ${months}${monthPrefix}`;
    } else {
      return `${years}${yearPrefix}`;
    }
  } else {
    if (months > 0) {
      return `${months}${monthPrefix}`;
    } else {
      return `${weeks}${weekPrefix}`;
    }
  }

}


export function calendarDate(date) {
  return moment(date).calendar({
    lastDay: '[Yesterday] [at] LT',
    sameDay: '[Today]',
    nextDay: '[Tomorrow]',
    lastWeek: '[Last] dddd [at] LT',
    nextWeek: 'dddd [at] LT',
    sameElse: 'L'
  });
}

export function fromNowDate(date, withoutSuffix?: boolean) {
  return moment(date).calendar({
    lastDay: '[Yesterday]',
    sameDay: '[Today]',
    lastWeek: () => {
      return `[${moment(date).fromNow(withoutSuffix)}]`;
    },
    sameElse: () => {
      return `[${moment(date).fromNow(withoutSuffix)}]`;
    }
  });
}

export function calendarDateWeek(date) {
  return moment(date).calendar({
    sameDay: '[This Week]',
    nextDay: '[Next Week]',
    nextWeek: '[Next Week]',
    lastDay: '[This Week]',
    lastWeek: '[Last Week]',
    sameElse: (now) => {
      const from = moment(date);
      now = moment(now);
      const thisMonthStart = moment().startOf('month');
      const lastMonthStart = moment().subtract(1, 'month').startOf('month');

      if (thisMonthStart.isBefore(from)) {
        return '[This Month]';
      } else if (lastMonthStart.isBefore(from)) {
        return '[Last Month]';
      } else {
        if (Number(from.format('YYYY')) - Number(now.format('YYYY')) < 0) {
          return `[${from.format('YYYY')}]`;
        }
        return `[${from.format('MMMM')}]`;
      }

    },
  });
}
