
import { AfterFileUploadEvent, toUseFileUploadProps, useFileUploads, UseFileUploads } from 'src/utils/fileUploads';
import { FieldProps } from 'formik';
import { Box, Button, makeStyles } from '@material-ui/core';
import { Theme } from 'src/theme';
import React, { FC, useCallback, useState } from 'react';
import { FileUpload } from 'src/components/FileUpload';

export interface FileUploadInputProps extends FieldProps, UseFileUploads {

}


const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  previewImage: {
    padding: theme.spacing(0.5),
    borderRadius: theme.spacing(0.5),
    overflow: 'hidden',
    border: `1px solid ${theme.palette.grey[400]}`,
    '& img': {
      height: 150,
      width: 150,
      objectFit: 'cover',
      display: 'block',
    }
  },
  changeButton: {
    marginRight: theme.spacing(1),
  },
  removeButton: {
    marginRight: theme.spacing(1),
    backgroundColor: theme.palette.error.main,
    color: theme.palette.error.contrastText,
    '&:hover': {
      backgroundColor: theme.palette.error.dark,
    }
  }
}));


const FileUploadInput: FC<FileUploadInputProps> = (props) => {
  const {
    multiple,
    form: {
      setFieldValue,
    },
    field: {
      name,
      value,
    },
  } = props;

  const classes = useStyles();

  const [showUploader, setShowUploader] = useState(multiple || (!multiple && !value));

  const handleAfterFileUpload = useCallback(
    (event: AfterFileUploadEvent) => {
      if (multiple) {
        setFieldValue(name, event.path);
      } else {
        setFieldValue(name, event.path && event.path[0]);
      }
    },
    [setFieldValue, name, multiple],
  )

  const handleChangeImage = useCallback(
    () => {
      setShowUploader(true);
    },
    [setShowUploader],
  )
  const handleRemoveImage = useCallback(
    () => {
      setShowUploader(true);
      setFieldValue(name, '');
    },
    [setShowUploader, setFieldValue, name],
  )

  const uploader = useFileUploads({
    afterFileUpload: handleAfterFileUpload,
    multiple,
  });

  return (
    <React.Fragment>
      {!showUploader && (
        <Box
          display="flex"
          alignItems="center"
          mb={2}
        >
          <Box
            flex="none"
            mr={2}
            className={classes.previewImage}
          >
            <img src={value} alt="Preview" />
          </Box>
          <Box
            flex="auto"
          >
            <Button
              color="primary"
              variant="contained"
              onClick={handleChangeImage}
              className={classes.changeButton}
            >
              Change
            </Button>
            <Button
              variant="contained"
              onClick={handleRemoveImage}
              className={classes.removeButton}
            >
              Remove
            </Button>
          </Box>
        </Box>
      )}
      { showUploader && (
        <FileUpload
          {...uploader}
          {...toUseFileUploadProps(props)}
        />
      )}
    </React.Fragment>
  )
}

export default FileUploadInput