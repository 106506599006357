import { CreateMultipleEnrollmentProgramAgeInput, IEnrollmentProgramAge } from 'src/types/program'
import { instanceApi } from 'src/utils/axios'
import { CRUDService } from './core/crudService'

export class EnrollmentProgramAgeService extends CRUDService<IEnrollmentProgramAge> {
  protected apiPath = '/api/app/enrollment-program-age'
  
  createMultiple(request: CreateMultipleEnrollmentProgramAgeInput) {
    return instanceApi.post<IEnrollmentProgramAge[]>(`${this.apiPath}/multiple-ages`, request)
  }

  getAgeByProgram(id: string) {
    return instanceApi.get(`${this.apiPath}/by-program/${id}`)
  }
}
