import { IAddNarrationSoundInput, IStory } from 'src/types/tours';
import { instanceApi } from 'src/utils/axios';
import { CRUDService } from './core/crudService';

export class StoryService extends CRUDService<IStory> {

  protected apiPath = 'api/app/story';

  // mapItem(file) {
  //   return {
  //     ...file,
  //     videoURL: MediaFileService.urlWithSasToken(file.videoURL),
  //     thumbnailImageURL: MediaFileService.urlWithSasToken(file.thumbnailImageURL)
  //   }
  // }

  updateStatus(id: string, status: 'publish' | 'unpublish') {
    return instanceApi.post(`${this.apiPath}/${id}/${status}`, {})
  }

  createVideoUrl(storyId: string) {
    return instanceApi.post(`${this.apiPath}/${storyId}/video-url`, {})
      .then((resp) => {
        resp.data = this.mapItem(resp.data);
        return resp;
      })
  }

  addNarrationSound(input: IAddNarrationSoundInput) {
    return instanceApi.post(`${this.apiPath}/narration-sound`, input)
      .then((resp) => {
        resp.data = this.mapItem(resp.data);
        return resp;
      })
  }

  createStreamVideoUrl(storyId: string) {
    return instanceApi.post(`${this.apiPath}/${storyId}/stream-video-url`, {})
      .then((resp) => {
        resp.data = this.mapItem(resp.data);
        return resp;
      })
  }

  getWithDetails(id: any) {
    return instanceApi.get(`${this.apiPath}/${id}/with-details`, {})
      .then((resp) => {
        resp.data = this.mapItem(resp.data);
        return resp;
      })
  }
}