
import { IPipelineStage } from 'src/types/pipeline';
import { CRUDService } from './core/crudService';
import { DealService } from './dealService';


const dealService = DealService.getInstance();
export class PipelineStageService extends CRUDService<IPipelineStage> {
    protected apiPath = 'api/app/pipeline-stage';
    protected fillable = ["name", "pipelineId", "stageOrder"];

    mapItem(row: IPipelineStage) {
        if (row.deals) {
            row.deals = row.deals.map(dealService.mapItem);
        }
        return row;
    }

}
