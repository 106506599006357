import { IFeatureFlag } from 'src/types/feature-flag'
import { instanceApi } from 'src/utils/axios'
import { CRUDService } from './core/crudService'

export class FeatureFlagService extends CRUDService<any> {
  protected apiPath = '/api/app/feature-flag'

  getFeatureFlagsByProvider(providerId: string) {
    return instanceApi.get<IFeatureFlag>(`${this.apiPath}/feature-flags/${providerId}`)
  }
}
