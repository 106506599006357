/* eslint-disable no-unused-vars */
import { IPagination } from 'src/types'
import {
  IGetTimelineByFamilyInput,
  INewTimeline,
  ITimelineByFilter
} from 'src/types/timeline'
import { instanceApi } from 'src/utils/axios'
import { CRUDService } from './core/crudService'

export class NewTimelineService extends CRUDService<INewTimeline> {
  protected apiPath = '/api/app/new-timeline'

  protected fillable = [
    'tenantId',
    'providerId',
    'familyId',
    'event',
    'type',
    'person',
    'description'
  ]

  byFamily(request: IGetTimelineByFamilyInput) {
    return instanceApi
      .get<IPagination<INewTimeline>>(`${this.apiPath}/by-family`, {
        params: request
      })
      .then((resp) => {
        resp.data.items = resp.data.items.map(this.mapItem)
        return resp
      })
  }

  byFilter(request: ITimelineByFilter) {
    return instanceApi
      .get<IPagination<INewTimeline>>(`${this.apiPath}/by-filter`, {
        params: request
      })
      .then((resp) => {
        resp.data.items = resp.data.items.map(this.mapItem)
        return resp
      })
  }

  getWithTemplate(request: any) {
    return instanceApi
      .get(`${this.apiPath}/by-date`, {
        params: request
      })
      .then((resp) => {
        return resp.data
      })
  }

  mapItem(item) {
    try {
      item.description = JSON.parse(item.description)
    } catch (error) {}
    return item
  }
}
