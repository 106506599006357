/* eslint-disable no-unused-vars */
import { IChild, IChildSearch } from './child'
import { IBaseEntity, IPagination, IPaginationRequest } from './core'
import { IDeal } from './deal'
import { IParent } from './parent'

export interface IFamily extends IBaseEntity {
  name?: string
  providerId?: string
  children?: IChild[]
  parents?: IParent[]
  primaryParent?: IParent
  lastDeal?: IDeal
  status?: string
  creationTimeUTC?: Date
}

export interface IGetByFilterInput {
  maxResultCount?: number
  skipCount?: number
  sorting?: string
  statuses?: string[]
  sources?: string[]
}

export interface IFamilyPaginationRequest extends IPaginationRequest {
  Sorting?: string
}

export interface IFamilyPagination<T> extends IPagination<T> {
  leadsCount?: number
  registeredCount?: number
  enrolledCount?: number
  activeCount?: number
  enrollmentCount?: number
  inactiveCount?: number
  allCount?: number
}

export interface IStartPreference extends IBaseEntity {
  familyId?: string
  providerZip?: string
  createdType?: number
  status?: number
  childSearch?: IChildSearch
}

export interface IBaseFamily {
  id?: string
  name?: string
  parentName?: string
  children?: IChild[]
  status?: string
  lastContact?: string
}

export interface ILeadsFamily extends IBaseFamily {
  leadStep?: string
  firstSeen?: string
  tour?: string
  startPreference?: IStartPreference
}

export interface IRegisteredFamily extends IBaseFamily {
  registrationDate?: string
  startDate?: string
  startTerm?: string
  onWaitlist?: boolean
}

export interface IFamilySearchResponse {
  id?: string
  name?: string
  primaryParentId?: string
}

export enum FamilyStatusEnum {
  LEAD = 'Lead',
  REGISTER = 'Registered',
  ACTIVE = 'Active'
}
