import {
  Box,
  makeStyles
} from "@material-ui/core";
import clsx from "clsx";
import { first } from "lodash";
import React, { FC, useCallback, useState } from "react";
import { Theme } from "src/theme";

export interface FileUploadEvent {
  value: File[];
}

export interface FileUploadDropZoneProps {
  className?: string;
  multiple?: boolean;
  onUpload?: (event: FileUploadEvent) => void
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: '100%',
    padding: theme.spacing(6),
    border: `1px dashed #E0E0E0`,
    borderRadius: theme.spacing(1),
  },
  dragging: {
    borderColor: theme.palette.background.dark
  },
  fileInput: {
    display: 'none',
  },
  contentArea: {
    maxWidth: 500,
    margin: 'auto',
    cursor: 'pointer',
  }
}));

export const FileUploadDropZone: FC<FileUploadDropZoneProps> = ({
  onUpload,
  multiple,
  className,
  children,
}) => {

  const classes = useStyles();
  const [dragging, setDragging] = useState(false);

  const dragenterListener = useCallback(
    (event: React.DragEvent<HTMLDivElement>) => {
      event.preventDefault();
      event.stopPropagation();

      if (event.dataTransfer.items && event.dataTransfer.items[0]) {
        setDragging(true);
      } else if (
        event.dataTransfer.types &&
        event.dataTransfer.types[0] === "Files"
      ) {
        setDragging(true);
      }
    }, [setDragging]
  );

  const dragleaveListener = useCallback(
    (event: React.DragEvent<HTMLDivElement>) => {
      event.preventDefault();
      event.stopPropagation();
      setDragging(false);
    }, [setDragging]
  );

  const dropListener = useCallback(
    (event: React.DragEvent<HTMLDivElement>) => {
      event.preventDefault();
      event.stopPropagation();
      setDragging(false);
      if (event.dataTransfer.files && event.dataTransfer.files.length > 0) {
        let files = Array.from(event.dataTransfer.files);
        if (!multiple) {
          files = [first(files)];
        }
        onUpload({
          value: files
        });
      }
    }, [setDragging, onUpload, multiple]
  );

  const onSelectFileClick = useCallback(
    (event) => {
      event.preventDefault();
      event.stopPropagation();
      const fileUploader = document.getElementById('fileUploader');
      if (fileUploader) {
        fileUploader.click();
      }
    }, []
  );

  const onFileChanged = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (event.target.files && event.target.files.length > 0) {
        onUpload({
          value: Array.from(event.target.files)
        });
        event.target.value = null;
      }
    }, [onUpload]
  );

  return (

    <Box
      onDragOver={dragenterListener}
      onDragEnter={dragenterListener}
      onDragLeave={dragleaveListener}
      onDrop={dropListener}
      className={clsx(classes.root, className, dragging ? classes.dragging : null)}
    >
      <Box
        className={classes.contentArea}
        display="flex"
        alignItems="center"
        alignContent="center"
        onClick={onSelectFileClick}
      >
        {children}
      </Box>
      <input
        type="file"
        multiple={multiple}
        id='fileUploader'
        className={classes.fileInput}
        onChange={onFileChanged}
      />
    </Box>
  )
};

FileUploadDropZone.defaultProps = {
  onUpload: () => { }
};
