/* eslint-disable no-unused-vars */
import {
  INotification,
  INotificationByProvider,
  IMarkAsReadInput
} from 'src/types/notification'
import { instanceApi } from 'src/utils'
import { CRUDService } from './core/crudService'

export class NotificationService extends CRUDService<INotification> {
  protected apiPath = 'api/app/notification'

  getNotificationsByProvider() {
    return instanceApi.get<INotificationByProvider>(
      `${this.apiPath}/notifications-by-current-provider`
    )
  }

  getUnreadNotificationsByProvider() {
    return instanceApi.get<INotificationByProvider>(
      `${this.apiPath}/notifications-by-current-provider?Status=0`
    )
  }

  getNotificationsOfLastMonthByProvider() {
    return instanceApi.get<INotificationByProvider>(
      `${this.apiPath}/notifications-by-current-provider?LastDays=30&Status=1`
    )
  }

  update(id: string | number, request: Partial<INotification> = {}) {
    return instanceApi.put<INotification>(`${this.apiPath}/` + id, request)
  }

  markAsRead(request: IMarkAsReadInput) {
    return instanceApi.post<string>(`${this.apiPath}/mark-as-read`, request)
  }
}
