/* eslint-disable no-unused-vars */
import {
  IPrimaryMarketingTemplate,
  IProviderSectionTemplate
} from 'src/types/email-template'
import { CRUDService } from './core/crudService'
import { instanceApi } from 'src/utils/axios'

export class ProviderSectionTemplateService extends CRUDService<IProviderSectionTemplate> {
  protected apiPath = 'api/app/provider-section-template'

  getSectionTemplates() {
    return instanceApi.get<IProviderSectionTemplate[]>(`${this.apiPath}`)
  }

  getPrimaryMarketingTemplates() {
    return instanceApi.get<IPrimaryMarketingTemplate>(
      `${this.apiPath}/primary-and-marketing`
    )
  }
}
