import { IBaseEntity } from './core'
import { IEnrollmentClass } from './enrollment-class'
import {
  IEnrollmentMedia,
  IEnrollmentMediaGroup,
  IEnrollmentMediaStyle
} from './enrollment-media'
import { IProvider } from './provider'
import { IEnrollmentSession } from './session'

export interface IProgram extends IBaseEntity {
  providerId?: string
  provider?: IProvider
  isNew?: boolean
  enrollmentClasses?: IEnrollmentClass[]
  name?: string
  description?: string
  isPublished?: boolean
  billing?: EnrollmentProgramBillingEnum
  status?: ProgramStatusEnum
  startDate?: string
  endDate?: string
  enrollmentStart?: string
  micrositeImageUrl?: string
  orderNumber?: number
  enrollmentProgramsAges?: IEnrollmentProgramAge[]
  sessions?: IEnrollmentSession[]
  enrollmentStatus?: EnrollmentStatusEnum
  enrollmentSessions?: IEnrollmentSession[]
  enrollmentMediaStyle?: IEnrollmentMediaStyle
  enrollmentMedia?: IEnrollmentMedia[]
  enrollmentMediaGroups?: IEnrollmentMediaGroup[]
  enrollmentMediaAndSessions?: (IEnrollmentSession | IEnrollmentMedia)[]
  isTouched?: boolean
  micrositeTimelines?: IEnrollmentTimeline[]
}

export interface IEnrollmentAge extends IBaseEntity {
  tenantId?: string
  providerId?: string
  name?: string
  minAgeChoice?: EnrollmentAgeChoiceEnum
  maxAgeChoice?: EnrollmentAgeChoiceEnum
  minAgeValue?: number
  maxAgeValue?: number
  minAge?: number
  maxAge?: number
  questionType?: number
  ageType?: number
  ageGroup?: number
}

export interface IEnrollmentProgramSchedule extends IBaseEntity {
  programStartDate?: string
  programEndDate?: string
  publish?: boolean
  programScheduleStatus?: string
  phasedEnrollment?: true
  name?: string
  earlyRegStart?: string
  earlyRegEnd?: string
  enrollmentStartDate?: string
  isDeleted?: true
  providerId?: string
  enrollmentClasses?: IEnrollmentClass[]
}

export interface IEnrollmentProgramAge extends IBaseEntity {
  providerId: string
  enrollmentProgramId: string
  enrollmentProgram: IProgram
  enrollmentAgeId?: string
  enrollmentAge?: IEnrollmentAge
}

export interface IUpdateMultipleSessionsInput {
  enrollmentProgramId: string
  enrollmentClasses: IEnrollmentClass[]
  isDeleteEnrollmentClasses?: boolean
}

export interface IUpdateMultipleClassesInput {
  enrollmentProgramId: string
  enrollmentSessions: IEnrollmentSession[]
  isDeleteEnrollmentClasses?: boolean
}

export interface CreateMultipleEnrollmentProgramAgeInput {
  enrollmentProgramId: string
  ageIds: string[]
  isDeleteAges?: boolean
}

export interface IEnrollmentTimeline {
  id?: string
  providerId?: string
  description?: string
  duration?: number
  name?: string
  order?: string | number
  enrollmentProgramId?: string
  micrositeTimelinesSlideshows?: any[]
}

export enum EnrollmentAgeChoiceEnum {
  WEEKS = 'Weeks',
  MONTHS = 'Months',
  YEARS = 'Years'
}

export enum ProgramStatusEnum {
  ACTIVE = 0,
  PLANNED = 1
}

export enum EnrollmentStatusEnum {
  ACTIVE = 0,
  PLANNED = 1,
  ONGOING = 2
}

export enum EnrollmentProgramBillingEnum {
  WEEKLY = 'weekly',
  MONTHLY = 'monthly',
  SINGLE_PAYMENT = 'single_payment',
  OTHER = 'other'
}
