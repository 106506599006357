import { cloneDeep } from 'lodash';
import { IChatBotSettings } from 'src/types';
import { instanceApi } from 'src/utils/axios';
import { CRUDService } from './core/crudService';

export class ProviderChatBotSettingService extends CRUDService<IChatBotSettings> {

    protected apiPath = '/api/app/provider-chat-bot-setting';

    getByProvider() {
        return instanceApi
            .get<IChatBotSettings>(`${this.apiPath}/by-provider`)
    }

    create(request: Partial<IChatBotSettings> = {}) {
        request = this.requestMap(request)
        return super.create(request)
    }

    update(id: string | number, request: Partial<IChatBotSettings> = {}) {
        request = this.requestMap(request)
        return super.update(id, request)
    }


    requestMap(data: IChatBotSettings) {
        const updatedData = cloneDeep(data);

        if (updatedData.providerChatQuestionGroups && !updatedData.providerChatQuestionGroupsInput) {
            updatedData.providerChatQuestionGroups.map((item) => {
                item.providerChatQuestionsInput = item.providerChatQuestions;
                delete item.providerChatQuestions;
                return item;
            })
            updatedData.providerChatQuestionGroupsInput = updatedData.providerChatQuestionGroups;
            delete updatedData.providerChatQuestionGroups;
        }

        return updatedData
    }

}
