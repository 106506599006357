import { ITour } from 'src/types/tours';
import { instanceApi } from 'src/utils/axios';
import { CRUDService } from './core/crudService';

export class TourService extends CRUDService<ITour> {

  protected apiPath = '/api/app/tour';

  updateStatus(id: string, status: 'publish' | 'unpublish') {
    return instanceApi.post(`${this.apiPath}/${id}/${status}`, {})
  }

}