/* eslint-disable no-unused-vars */
import { ITask, ITaskByProviderResponse } from 'src/types/task'
import { instanceApi } from 'src/utils'
import { CRUDService } from './core/crudService'

export class TaskService extends CRUDService<ITask> {
  protected apiPath = 'api/app/task'

  getTasksByProvider() {
    return instanceApi.get<ITaskByProviderResponse>(
      `${this.apiPath}/tasks-by-current-provider`
    )
  }
}
