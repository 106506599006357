import { IEnrollmentClassOption } from 'src/types/enrollment-class-option'
import { CRUDService } from './core/crudService'
import { instanceApi } from 'src/utils/axios'

export class EnrollmentClassOptionService extends CRUDService<IEnrollmentClassOption> {
  protected apiPath = '/api/app/enrollment-class-options'

  createEnrollmentClassOptions(request: IEnrollmentClassOption) {
    return instanceApi.post<IEnrollmentClassOption>(`${this.apiPath}`, request)
  }

  updateEnrollmentClassOptions(
    id: string | number,
    request: IEnrollmentClassOption
  ) {
    return instanceApi.put<IEnrollmentClassOption>(
      `${this.apiPath}/${id}`,
      request
    )
  }

  deleteEnrollmentClassOptions(id: string | number) {
    return instanceApi.delete<IEnrollmentClassOption>(`${this.apiPath}/${id}`)
  }
}
