/* eslint-disable no-unused-vars */
import { ITemplateVariable } from 'src/types/email-template'
import { instanceApi } from 'src/utils/axios'
import { CRUDService } from './core/crudService'

export class TemplateVariableService extends CRUDService<ITemplateVariable> {
  protected apiPath = '/api/app/template-variable'

  variables() {
    return instanceApi.get<ITemplateVariable[]>(`${this.apiPath}/variables`)
  }

  variablesValues(request: any) {
    return instanceApi.get<ITemplateVariable[]>(
      `${this.apiPath}/variables-values`,
      { params: request }
    )
  }
}
