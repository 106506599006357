import { IAddress } from './address'
import { IBaseEntity } from './core'

export interface IChildSearch extends IBaseEntity {
  childId?: string
  childrSearchTerms?: any[]
  familySearchId?: string
  startDate?: string
  startResp?: number
}

export interface IChild extends IBaseEntity {
  firstName?: string
  lastName?: string
  notes?: string
  age?: number
  imageUrl?: string
  startDate?: string
  endDate?: string
  familyId?: string
  familyName?: string
  parentId?: string
  parentName?: string
  addressId?: string
  address?: IAddress
  status?: ChildStatusEnum
  dateOfBirth?: Date
  startTimeFrame?: string
  startImmediate?: string
  startUnsure?: string
  termName?: string
  childSearch?: IChildSearch[]
  formattedAge?: string
}

export enum ChildStatusEnum {
  RIGHT_NOW = 0,
  NEXT_SEVERAL_WEEKS = 1,
  NEXT_SCHOOL_YEARS = 2,
  NOT_LOOKING_YET = 3
}
