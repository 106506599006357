import { IBaseEntity } from './core';
import { ISlide } from './slide';

export interface ISlideshowSlide extends IBaseEntity {
  providerId?: string;
  slideshowId?: string;
  slideId?: string;
  fileOrder?: number;
  slide?: ISlide;
}

export interface ISlideshow extends IBaseEntity {
  providerId?: string;
  providerName?: string;
  title?: string;
  description?: string;
  thumbnailImageURL?: string;
  status?: SlideshowStatusEnum;
  isPublished?: boolean;
  slideshowSlides?: ISlideshowSlide[];
}

export enum SlideshowStatusEnum {
  UNPUBLISHED = 0,
  PUBLISHED = 1
}
