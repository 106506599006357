import { instanceApi } from 'src/utils/axios';
import { CRUDService } from './core/crudService';

interface AllowedOriginsRequestProps {
  providerId: string;
  name: string;
  url: string;
  enabled: boolean;
}

export class AllowedOrigins extends CRUDService<any> {
  protected apiPath = '/api/app/allowed-origins';

  getAllowedOriginsById(id: string) {
    return instanceApi.get<any>(`${this.apiPath}/${id}`);
  }

  getAllAllowedOrigins() {
    return instanceApi.get<any>(`${this.apiPath}`);
  }

  createAllowedOrigins(request: AllowedOriginsRequestProps) {
    return instanceApi.post<any>(`${this.apiPath}`, request);
  }

  updateAllowedOrigins(id, request: AllowedOriginsRequestProps) {
    return instanceApi.put<any>(`${this.apiPath}/${id}`, request);
  }

  deleteAllowedOriginsById(id: string) {
    return instanceApi.delete<any>(`${this.apiPath}/${id}`);
  }

}
