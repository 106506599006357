/* eslint-disable no-unused-vars */
import {
  IProviderGettingStarted,
  IGettingStartedActionInput
} from 'src/types/providerGettingStarted'
import { CRUDService } from './core/crudService'
import { instanceApi } from 'src/utils'

export class ProviderGettingStartedService extends CRUDService<IProviderGettingStarted> {
  protected apiPath = 'api/app/provider-getting-started'
  protected fillable = []

  get() {
    return instanceApi.get<IProviderGettingStarted>(`${this.apiPath}`)
  }

  updateGettingStartedStatus(id: string) {
    return instanceApi.put<IProviderGettingStarted>(`${this.apiPath}/${id}`, {
      completedGettingStartedGuide: true
    })
  }

  createGettingStartedAction(request: IGettingStartedActionInput) {
    return instanceApi.post<IProviderGettingStarted>(
      `${this.apiPath}/action`,
      request
    )
  }
}
