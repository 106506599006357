/* eslint-disable no-unused-vars */
import { IProviderApproachElements } from 'src/types/provider'
import { instanceApi } from 'src/utils'
import { CRUDService } from './core/crudService'

export class ProviderBrandingProfileService extends CRUDService<IProviderApproachElements> {
  protected apiPath = '/api/app/provider-branding-profile'

  getInfoByType(type: string | number) {
    return instanceApi.get<any>(`${this.apiPath}/by-type?type=${type}`)
  }

  setInfo(request: any) {
    return instanceApi.post<any>(`${this.apiPath}`, request)
  }

  updateInfo(providerBrandingProfileId: string | number, request: any) {
    return instanceApi.put<any>(
      `${this.apiPath}/${providerBrandingProfileId}`,
      request
    )
  }
}
