import { find } from "lodash";

export const enumToKeyValue = (object: any) => {
  const keyValue = [];
  for (const key in object) {
    if (Object.prototype.hasOwnProperty.call(object, key)) {
      const value: any = object[key];
      if (isNaN(key as any)) {
        keyValue.push({ key, value })
      }
    }
  }

  return keyValue;
};

export const enumKeyByValue = (object: any, value:any) => {
  const keyValue = enumToKeyValue(object);
  const obj = find(keyValue, { value })
  return obj?.key;
};

